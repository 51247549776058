<template>
  <form method="#" action="#" ref="frame">
    <template v-if="isModal">
      <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: white; z-index: 10;" v-if="!initialized"></div>
    </template>
    <template v-else>
      <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: #f5f5f5; z-index: 10;" v-if="!initialized"></div>
    </template>
    <modal name="comment" :draggable="true" :clickToClose="false" height="auto" :width="700">
      <div class="modal-body">
        <div>
          <button type="button" class="btn btn-sm btn-default btn-danger" @click="hideComment">
            <span class="btn-label"><i class="ti-close"></i></span>
            {{$t('button.close')}}
          </button>
        </div>
        <div style="padding: 20px;">
          {{selectedComment}}
        </div>
      </div>
    </modal>
    <modal name="approver" :draggable="true" :clickToClose="false" height="auto" :width="700">
      <div class="modal-body">
        <EmployeeSearch :orgSearchCond="searchCond" :is-modal="true" :search-all="true" @close="hideApproverSearch"/>
      </div>
    </modal>
    <template v-if="applicationFormCode === '0000000016'">
      <modal name="attendanceRecord" :draggable="true" :clickToClose="false" height="auto" :width="1600" :pivotX="0.8">
        <div class="modal-body">
          <AttendanceRecordDetail @close="hideAttendanceRecord"/>
        </div>
      </modal>
    </template>
    <template v-if="applicationInfoDto.re_application_number && applicationInfoDto.re_application_number !== 0">
      <modal name="applyWorkflow" :draggable="true" :clickToClose="false" height="auto" :width="1200" :pivotX="0.5">
        <div class="modal-body">
          <Workflow :application-form-code="applicationFormCode" :apply-record="reapplicationRecord" :is-modal="true" @close="hideWorkflow"/>
        </div>
      </modal>
      <modal name="approveWorkflow" :draggable="true" :clickToClose="false" height="auto" :width="1200" :pivotX="0.5">
        <div class="modal-body">
          <Workflow :application-form-code="applicationFormCode" :approve-record="reapplicationRecord" :is-modal="true" @close="hideWorkflow"/>
        </div>
      </modal>
    </template>
    <ValidationObserver ref="observer">
      <div class="row" :key="componentKey">
        <div class="col-md-12" v-if="isModal">
          <button type="button" class="btn btn-sm btn-default btn-danger" @click="doClose" :disabled="isChanging">
            <span class="btn-label"><i class="ti-close"></i></span>
            {{$t('button.close')}}
          </button>
        </div>
        <div class="col-md-6">
        </div>
        <div class="col-md-6">
          <Help class="pull-right" :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;" :movie-key="movieKey"/>
        </div>
        <div class="col-md-12">
          <MessageArea :page-type="isModal ? pageType.detail : pageType.detail" :message="message" :placeholders="[pageTitle]"/>
        </div>
        <div class="col-md-12" :style="contentStyle" ref="frameModal">
          <div>
            <Applicant 
              v-model="applicationInfoDto" 
              :has-boss="hasBoss" 
              :combo-data="comboData" 
              :labels="labels" 
              :is-read-only="isReadOnly"
              :can-target-select="canTargetSelect"
              :application-form-code="selectedApplicationFormCode"
              @initApply="initApply"
              @updateRoute="updateRoute"
              @showWorkflow="showWorkflow"
            />
          </div>
          <template v-if="isModal || !!applicationInfoDto.target_employee_code">
            <div>
              <template v-if="applicationFormCode === '0000000001'">
                <ImprintCorrection 
                  v-model="application" 
                  :combo-data="comboData" 
                  :labels="labels" 
                  :is-read-only="isReadOnly"
                  :is-modal="isModal"
                  :err-msgs="errMsgs3"
                  ref="imprintCorrection"
                  @initApply="initApply"
                />
              </template>
              <template v-else-if="applicationFormCode === '0000000002'">
                <Overtime 
                  v-model="application" 
                  :combo-data="comboData" 
                  :labels="labels" 
                  :is-read-only="isReadOnly"
                  :is-modal="isModal"
                  :err-msgs="errMsgs3"
                  ref="overtime"
                  @initApply="initApply"
                />
              </template>
              <template v-else-if="applicationFormCode === '0000000003'">
                <Ground 
                  v-model="application" 
                  :combo-data="comboData" 
                  :labels="labels" 
                  :is-read-only="isReadOnly"
                  :is-modal="isModal"
                  ref="ground"
                  @initApply="initApply"
                />
              </template>
              <template v-else-if="applicationFormCode === '0000000004'">
                <LateNightOverwork 
                  v-model="application" 
                  :combo-data="comboData" 
                  :labels="labels" 
                  :is-read-only="isReadOnly"
                  :is-modal="isModal"
                  :err-msgs="errMsgs3"
                  ref="lateNightOverwork"
                  @initApply="initApply"
                />
              </template>
              <template v-else-if="applicationFormCode === '0000000005' || applicationFormCode === '0000000006'">
                <LateEarlyDepartureTime 
                  v-model="application" 
                  :combo-data="comboData" 
                  :labels="labels" 
                  :is-read-only="isReadOnly"
                  :is-modal="isModal"
                  ref="lateEarlyDepartureTime"
                  @initApply="initApply"
                  @applicationTypeChanged="applicationTypeChanged"
                />
              </template>
              <template v-else-if="applicationFormCode === '0000000007'">
                <HolidayWork 
                  v-model="application" 
                  :combo-data="comboData" 
                  :labels="labels" 
                  :is-read-only="isReadOnly"
                  :is-modal="isModal"
                  :err-msgs="errMsgs3"
                  ref="holidayWork"
                  @initApply="initApply"
                />
              </template>
              <template v-else-if="applicationFormCode === '0000000008'">
                <ParentalLeave 
                  v-model="application" 
                  :combo-data="comboData" 
                  :labels="labels" 
                  :is-read-only="isReadOnly"
                  :is-modal="isModal"
                  :err-msgs="errMsgs3"
                  ref="parentalLeave"
                  @initApply="initApply"
                  @calcDays="calcDays"
                />
              </template>
              <template v-else-if="applicationFormCode === '0000000009' || applicationFormCode === '0000000010'">
                <Holiday
                  v-model="application" 
                  :combo-data="comboData" 
                  :labels="labels" 
                  :is-read-only="isReadOnly"
                  :is-modal="isModal"
                  :err-msgs="errMsgs3"
                  ref="holiday"
                  @initApply="initApply"
                  @calcDays="calcDays"
                />
              </template>
              <template v-else-if="applicationFormCode === '0000000011' || applicationFormCode === '0000000012'">
                <TransferHolidayWork 
                  v-model="application" 
                  :combo-data="comboData" 
                  :labels="labels" 
                  :is-read-only="isReadOnly"
                  :is-modal="isModal"
                  ref="transferHolidayWork"
                  @initApply="initApply"
                />
              </template>
              <template v-else-if="applicationFormCode === '0000000013'">
                <TimeLimitExceeded 
                  v-model="application" 
                  :combo-data="comboData" 
                  :labels="labels" 
                  :is-read-only="isReadOnly"
                  :is-modal="isModal"
                  ref="timeLimitExceeded"
                  @initApply="initApply"
                />
              </template>
              <template v-else-if="applicationFormCode === '0000000014' || applicationFormCode === '0000000015'">
                <LeaveJob 
                  v-model="application" 
                  :combo-data="comboData" 
                  :labels="labels" 
                  :is-read-only="isReadOnly"
                  :is-modal="isModal"
                  ref="leaveJob"
                  @initApply="initApply"
                />
              </template>
              <template v-else-if="applicationFormCode === '0000000016'">
                <AttendanceRecord 
                  v-model="application" 
                  :combo-data="comboData" 
                  :labels="labels" 
                  :is-read-only="true"
                  :err-msgs="errMsgs4"
                  :is-modal="isModal"
                  ref="attendanceRecord"
                  @showAttendanceRecord="showAttendanceRecord"
                />
              </template>
              <template v-else-if="applicationFormCode === '0000000018'">
                <AddressChange 
                  v-model="application" 
                  :combo-data="comboData" 
                  :labels="labels" 
                  :is-read-only="isReadOnly"
                  :err-msgs="errMsgs3"
                  ref="addressChange"
                />
              </template>
              <template v-else-if="applicationFormCode === '0000000019'">
                <CommutingRouteChange 
                  v-model="application" 
                  :combo-data="comboData" 
                  :labels="labels" 
                  :is-read-only="isReadOnly"
                  :err-msgs="errMsgs3"
                  ref="commutingRouteChange"
                />
              </template>
              <template v-else-if="applicationFormCode === '0000000020'">
                <PersonalInformationChange 
                  v-model="application" 
                  :combo-data="comboData" 
                  :labels="labels" 
                  :is-read-only="isReadOnly"
                  :err-msgs="errMsgs3"
                  ref="personalInformationChange"
                />
              </template>
            </div>
            <template v-if="applicationInfoDto.screen_mode === '4' || applicationInfoDto.screen_mode === '5' || applicationInfoDto.screen_mode === '15'">
              <div>
                <Approval 
                  v-model="approveInfoDto"
                  :combo-data="comboData" 
                  :labels="labels"
                  :is-read-only="isChanging || isChanged"
                />
              </div>
            </template>
            <template v-if="approveRecord && (applicationInfoDto.screen_mode === '6' || applicationInfoDto.screen_mode === '7')">
              <div>
                <Attachment 
                  v-model="approveInfoDto"
                  :combo-data="comboData" 
                  :labels="labels"
                />
              </div>
            </template>
            <template v-if="applicationInfoDto.screen_mode === '' || applicationInfoDto.screen_mode === '1' || applicationInfoDto.screen_mode === '2'">
              <div>
                <Approver 
                  route-type="1" 
                  v-model="individualRoute" 
                  :combo-data="comboData" 
                  :labels="labels" 
                  :is-read-only="isReadOnly"
                  :err-msgs="errMsgs1"
                  ref="individualRoute"
                  @showApproverSearch="showApproverSearch"
                />
              </div>
            </template>
            <template v-else-if="approveRecord || !(applicationInfoDto.application_status === 1 || applicationInfoDto.application_status === 6 || applicationInfoDto.application_status === 7)">
              <div>
                <RouteHistory
                  route-type="1" 
                  v-model="individualRoute" 
                  :labels="labels" 
                  @showComment="showComment"
                />
              </div>
            </template>
            <template v-if="applicationInfoDto.screen_mode === '' || applicationInfoDto.screen_mode === '1' || applicationInfoDto.screen_mode === '2'">
              <div>
                <Approver 
                  route-type="2" 
                  v-model="commonRoute" 
                  :combo-data="comboData" 
                  :labels="labels" 
                  :is-read-only="isReadOnly"
                  :err-msgs="errMsgs2"
                  ref="commonRoute"
                  @showApproverSearch="showApproverSearch"
                />
              </div>
            </template>
            <template v-else-if="approveRecord || !(applicationInfoDto.application_status === 1 || applicationInfoDto.application_status === 6 || applicationInfoDto.application_status === 7)">
              <div>
                <RouteHistory
                  route-type="2" 
                  v-model="commonRoute" 
                  :labels="labels" 
                  @showComment="showComment"
                />
              </div>
            </template>
            <template v-if="historyRoute.length > 0">
              <div>
                <RouteHistory
                  route-type="3" 
                  v-model="historyRoute" 
                  :labels="labels" 
                  @showComment="showComment"
                />
              </div>
            </template>
          </template>
        </div>
        <template v-if="!!applicationInfoDto.target_employee_code">
          <div class="col-sm-12">
            <template v-if="applicationInfoDto.screen_mode === '' && roles.entry === 1">
              <button type="button" class="btn btn-sm btn-default btn-info" :disabled="isChanging || isChanged" @click="saveAsDraft">{{$t('button.draft')}}</button>
              <button type="button" class="btn btn-sm btn-default btn-info" :disabled="isChanging || isChanged" @click="submitApplication">{{$t('button.apply')}}</button>
            </template>
            <template v-if="applicationInfoDto.screen_mode === '1' && roles.entry === 1">
              <template v-if="applicationFormCode !== '0000000016'">
                <button type="button" class="btn btn-sm btn-default btn-info" :disabled="isChanging || isChanged" @click="saveAsDraft">{{$t('button.draft')}}</button>
              </template>
              <button type="button" class="btn btn-sm btn-default btn-info" :disabled="isChanging || isChanged" @click="submitApplication">{{$t('button.apply')}}</button>
            </template>
            <template v-else-if="applicationInfoDto.screen_mode === '2'">
              <button type="button" class="btn btn-sm btn-default btn-info" :disabled="isChanging || isChanged" @click="cancelApplication">{{$t('button.drop')}}</button>
              <button type="button" class="btn btn-sm btn-default btn-info" :disabled="isChanging || isChanged" @click="saveAsDraft">{{$t('button.draft')}}</button>
              <button type="button" class="btn btn-sm btn-default btn-info" :disabled="isChanging || isChanged" @click="submitApplication">{{$t('button.apply')}}</button>
            </template>
            <template v-else-if="applicationInfoDto.screen_mode === '3'">
              <button type="button" class="btn btn-sm btn-default btn-info" :disabled="isChanging || isChanged" @click="cancelApplication">{{$t('button.drop')}}</button>
              <button type="button" class="btn btn-sm btn-default btn-info" :disabled="isChanging || isChanged" @click="submitApplication">{{$t('button.apply')}}</button>
            </template>
            <template v-else-if="applicationInfoDto.screen_mode === '4'">
              <button type="button" class="btn btn-sm btn-default btn-info" :disabled="isChanging || isChanged" @click="remandApplication">{{$t('button.remand')}}</button>
              <button type="button" class="btn btn-sm btn-default btn-info" :disabled="isChanging || isChanged" @click="pendingApplication">{{$t('button.hold')}}</button>
              <button type="button" class="btn btn-sm btn-default btn-info" :disabled="isChanging || isChanged" @click="rejectApplication">{{$t('button.deny')}}</button>
              <button type="button" class="btn btn-sm btn-default btn-info" :disabled="isChanging || isChanged" @click="approveApplication">{{$t('button.approve')}}</button>
            </template>
            <template v-else-if="applicationInfoDto.screen_mode === '5'">
              <button type="button" class="btn btn-sm btn-default btn-info" :disabled="isChanging || isChanged" @click="preapproveApplication">{{$t('button.precede_approve')}}</button>
              <button type="button" class="btn btn-sm btn-default btn-info" :disabled="isChanging || isChanged" @click="pullupApplication">{{$t('button.skip_approve')}}</button>
            </template>
            <template v-else-if="applicationInfoDto.screen_mode === '6'">
              <button type="button" class="btn btn-sm btn-default btn-info" :disabled="isChanging || isChanged" @click="confirmApplication">{{$t('button.confirm')}}</button>
            </template>
            <template v-else-if="applicationInfoDto.screen_mode === '7'">
            </template>
            <template v-else-if="applicationInfoDto.screen_mode === '8'">
              <button type="button" class="btn btn-sm btn-default btn-info" :disabled="isChanging || isChanged" @click="correctApplication">{{$t('button.reapply')}}</button>
            </template>
            <template v-else-if="applicationInfoDto.screen_mode === '9'">
              <button type="button" class="btn btn-sm btn-default btn-info" :disabled="isChanging || isChanged" @click="pullbackApplication">{{$t('button.pull_back')}}</button>
              <button type="button" class="btn btn-sm btn-default btn-info" :disabled="isChanging || isChanged" @click="correctApplication">{{$t('button.reapply')}}</button>
            </template>
            <template v-else-if="applicationInfoDto.screen_mode === '10'">
              <button type="button" class="btn btn-sm btn-default btn-info" :disabled="isChanging || isChanged" @click="cancelApplication">{{$t('button.drop')}}</button>
              <button type="button" class="btn btn-sm btn-default btn-info" :disabled="isChanging || isChanged" @click="correctApplication">{{$t('button.reapply')}}</button>
            </template>
            <template v-else-if="applicationInfoDto.screen_mode === '11'">
              <button type="button" class="btn btn-sm btn-default btn-info" :disabled="isChanging || isChanged" @click="cancelApplication">{{$t('button.drop')}}</button>
              <button type="button" class="btn btn-sm btn-default btn-info" :disabled="isChanging || isChanged" @click="pullbackApplication">{{$t('button.pull_back')}}</button>
            </template>
            <template v-else-if="applicationInfoDto.screen_mode === '12'">
              <button type="button" class="btn btn-sm btn-default btn-info" :disabled="isChanging || isChanged" @click="cancelApplication">{{$t('button.drop')}}</button>
              <button type="button" class="btn btn-sm btn-default btn-info" :disabled="isChanging || isChanged" @click="pullbackApplication">{{$t('button.pull_back')}}</button>
              <button type="button" class="btn btn-sm btn-default btn-info" :disabled="isChanging || isChanged" @click="correctApplication">{{$t('button.reapply')}}</button>
            </template>
            <template v-else-if="applicationInfoDto.screen_mode === '13'">
              <button type="button" class="btn btn-sm btn-default btn-info" :disabled="isChanging || isChanged" @click="cancelApplication">{{$t('button.drop')}}</button>
            </template>
            <template v-else-if="applicationInfoDto.screen_mode === '14'">
              <button type="button" class="btn btn-sm btn-default btn-info" :disabled="isChanging || isChanged" @click="pullbackApplication">{{$t('button.pull_back')}}</button>
            </template>
            <template v-else-if="applicationInfoDto.screen_mode === '15' && isStagnation">
              <button type="button" class="btn btn-sm btn-default btn-info" :disabled="isChanging || isChanged" @click="forceApprove">{{$t('button.force_approve')}}</button>
            </template>
          </div>
        </template>        
      </div>
    </ValidationObserver>
    <template v-if="!isModal">
      <br>
      <br>
      <br>
    </template>
  </form>
</template>
<script>
  import Applicant from './Applicant'
  import Approval from './Approval'
  import Attachment from './Attachment'
  import Approver from './Approver'
  import RouteHistory from './RouteHistory'
  import ImprintCorrection from './ImprintCorrection'
  import Overtime from './Overtime'
  import Ground from './Ground'
  import LateNightOverwork from './LateNightOverwork'
  import LateEarlyDepartureTime from './LateEarlyDepartureTime'
  import HolidayWork from './HolidayWork'
  import Holiday from './Holiday'
  import ParentalLeave from './ParentalLeave'
  import TransferHolidayWork from './TransferHolidayWork'
  import TimeLimitExceeded from './TimeLimitExceeded'
  import AttendanceRecord from './AttendanceRecord'
  import LeaveJob from './LeaveJob'
  import AddressChange from './AddressChange'
  import CommutingRouteChange from './CommutingRouteChange'
  import PersonalInformationChange from './PersonalInformationChange'
  import EmployeeSearch from '@/components/Dashboard/Views/Components/EmployeeSearch'
  import swal from 'sweetalert2'
  import mixin from '@/lib/mixin'

  export default{
    name: 'Workflow',
    mixins: [mixin],
    components: {
      Applicant,
      Approval,
      Attachment,
      Approver,
      RouteHistory,
      ImprintCorrection,
      Overtime,
      Ground,
      LateNightOverwork,
      LateEarlyDepartureTime,
      HolidayWork,
      Holiday,
      ParentalLeave,
      TransferHolidayWork,
      TimeLimitExceeded,
      AttendanceRecord,
      LeaveJob,
      AddressChange,
      CommutingRouteChange,
      PersonalInformationChange,
      EmployeeSearch,
      AttendanceRecordDetail: () => import('@/components/Dashboard/Views/Working/AttendanceRecordDetail')
    },
    props: {
      applicationNumber: Number,
      applicationFormCode: String,
      attendanceRecord: Object,
      isModal: Boolean,
      applyRecord: Object,
      approveRecord: Object
    },
    computed: {
      movieKey () {
        switch (this.applicationFormCode) {
          case '0000000001':
            return 'imprint_correction'
          default:
            return ''
        }
      },
      screenCode () {
        switch (this.applicationFormCode) {
          case '0000000001':
            return '04-005'
          case '0000000002':
            return '04-007'
          case '0000000003':
            return '04-013'
          case '0000000004':
            return '04-008'
          case '0000000005':
          case '0000000006':
            return '04-006'
          case '0000000007':
            return '04-009'
          case '0000000008':
            return '04-011'
          case '0000000009':
          case '0000000010':
            return '04-014'
          case '0000000011':
          case '0000000012':
            return '04-010'
          case '0000000013':
            return '04-011'
          case '0000000014':
          case '0000000015':
            return '04-012'
          case '0000000016':
            return '04-004'
          case '0000000018':
            return '04-001'
          case '0000000019':
            return '04-002'
          case '0000000020':
            return '04-003'
          default:
            return ''
        }
      },
      viewUrl () {
        switch (this.applicationFormCode) {
          case '0000000001':
            return '/workflow/view/wf_imprint_correction/apply'
          case '0000000002':
            return '/workflow/view/wf_overtime/apply'
          case '0000000003':
            return '/workflow/view/wf_ground_confirm_employee/apply'
          case '0000000004':
            return '/workflow/view/wf_late_night_overwork/apply'
          case '0000000005':
          case '0000000006':
            return '/workflow/view/wf_late_time_early_departure_time/apply'
          case '0000000007':
            return '/workflow/view/wf_holiday_work/apply'
          case '0000000008':
            return '/workflow/view/wf_parental_leave/apply'
          case '0000000009':
          case '0000000010':
            return '/workflow/view/wf_holiday/apply'
          case '0000000011':
          case '0000000012':
            return '/workflow/view/wf_transfer_holiday_work/apply'
          case '0000000013':
            return '/workflow/view/wf_time_limit_exceeded/apply'
          case '0000000014':
          case '0000000015':
            return '/workflow/view/wf_leave_job/apply'
          case '0000000016':
            return '/workflow/view/wf_attendance_record/apply'
          case '0000000018':
            return '/workflow/view/wf_address_change/apply'
          case '0000000019':
            return '/workflow/view/wf_commuting_route_change/apply'
          case '0000000020':
            return '/workflow/view/wf_personal_information_change/apply'
          default:
            return ''
        }
      },
      initApproveUrl () {
        switch (this.applicationFormCode) {
          case '0000000001':
            return '/workflow/init/wf_imprint_correction/approve'
          case '0000000002':
            return '/workflow/init/wf_overtime/approve'
          case '0000000003':
            return '/workflow/init/wf_ground_confirm_employee/approve'
          case '0000000004':
            return '/workflow/init/wf_late_night_overwork/approve'
          case '0000000005':
          case '0000000006':
            return '/workflow/init/wf_late_time_early_departure_time/approve'
          case '0000000007':
            return '/workflow/init/wf_holiday_work/approve'
          case '0000000008':
            return '/workflow/init/wf_parental_leave/approve'
          case '0000000009':
          case '0000000010':
            return '/workflow/init/wf_holiday/approve'
          case '0000000011':
          case '0000000012':
            return '/workflow/init/wf_transfer_holiday_work/approve'
          case '0000000013':
            return '/workflow/init/wf_time_limit_exceeded/approve'
          case '0000000014':
          case '0000000015':
            return '/workflow/init/wf_leave_job/approve'
          case '0000000016':
            return '/workflow/init/wf_attendance_record/approve'
          case '0000000018':
            return '/workflow/init/wf_address_change/approve'
          case '0000000019':
            return '/workflow/init/wf_commuting_route_change/approve'
          case '0000000020':
            return '/workflow/init/wf_personal_information_change/approve'
          default:
            return ''
        }
      },
      initApplyUrl () {
        switch (this.applicationFormCode) {
          case '0000000001':
            return '/workflow/init/wf_imprint_correction/apply'
          case '0000000002':
            return '/workflow/init/wf_overtime/apply'
          case '0000000003':
            return '/workflow/init/wf_ground_confirm_employee/apply'
          case '0000000004':
            return '/workflow/init/wf_late_night_overwork/apply'
          case '0000000005':
          case '0000000006':
            return '/workflow/init/wf_late_time_early_departure_time/apply'
          case '0000000007':
            return '/workflow/init/wf_holiday_work/apply'
          case '0000000008':
            return '/workflow/init/wf_parental_leave/apply'
          case '0000000009':
          case '0000000010':
            return '/workflow/init/wf_holiday/apply'
          case '0000000011':
          case '0000000012':
            return '/workflow/init/wf_transfer_holiday_work/apply'
          case '0000000013':
            return '/workflow/init/wf_time_limit_exceeded/apply'
          case '0000000014':
          case '0000000015':
            return '/workflow/init/wf_leave_job/apply'
          case '0000000016':
            return '/workflow/init/wf_attendance_record/apply'
          case '0000000018':
            return '/workflow/init/wf_address_change/apply'
          case '0000000019':
            return '/workflow/init/wf_commuting_route_change/apply'
          case '0000000020':
            return '/workflow/init/wf_personal_information_change/apply'
          default:
            return ''
        }
      },
      isReadOnly () {
        return this.applicationInfoDto ? (this.applicationInfoDto.screen_mode !== '' && this.applicationInfoDto.screen_mode !== '1' && this.applicationInfoDto.screen_mode !== '2' && this.applicationInfoDto.screen_mode !== '3') || this.isChanging || this.isChanged : true
      },
      canTargetSelect () {
        return !this.attendanceRecord
      },
      isStagnation () {
        return window.currentUrl === '/09/menu09Screen012/search'
      }
    },
    data () {
      return {
        componentKey: 0,
        applicationInfoDto: {},
        hasBoss: false,
        application: {},
        approveInfoDto: {},
        individualRoute: [],
        commonRoute: [],
        historyRoute: [],
        errMsgs1: [],
        errMsgs2: [],
        errMsgs3: [],
        errMsgs4: [],
        comboData: {},
        labels: {},
        roles: {},
        reapplicationRecord: {},
        selectedApplicationFormCode: '',
        selectedComment: '',
        initData: undefined,
        isChanging: false,
        isChanged: false,
        searchCond: {
          company_code: this.$store.state.loginUser.companyCode,
          office_code: '',
          group_code: '',
          team_code: '',
          office_range: 2,
          group_range: 2,
          team_range: 2,
          search_employee_code: '',
          employee_classification_code: '',
          employee_code: '',
          search_type: '1',
          retirement: false
        },
        selectedRouteType: 1,
        attendanceRecordErrors: [],
        contentStyle: '',
        isDeleteStampPopup: false,
        deleteStampMessage: '',
        deleteStampMessageAction: '',
        groundList: [],
        isApplicationExist: false
      }
    },
    methods: {
      getApproveUrl (operation) {
        switch (this.applicationFormCode) {
          case '0000000001':
            return `/workflow/${operation}/wf_imprint_correction`
          case '0000000002':
            return `/workflow/${operation}/wf_overtime`
          case '0000000003':
            return `/workflow/${operation}/wf_ground_confirm_employee`
          case '0000000004':
            return `/workflow/${operation}/wf_late_night_overwork`
          case '0000000005':
          case '0000000006':
            return `/workflow/${operation}/wf_late_time_early_departure_time`
          case '0000000007':
            return `/workflow/${operation}/wf_holiday_work`
          case '0000000008':
            return `/workflow/${operation}/wf_parental_leave`
          case '0000000009':
          case '0000000010':
            return `/workflow/${operation}/wf_holiday`
          case '0000000011':
          case '0000000012':
            return `/workflow/${operation}/wf_transfer_holiday_work`
          case '0000000013':
            return `/workflow/${operation}/wf_time_limit_exceeded`
          case '0000000014':
          case '0000000015':
            return `/workflow/${operation}/wf_leave_job`
          case '0000000016':
            return `/workflow/${operation}/wf_attendance_record`
          case '0000000018':
            return `/workflow/${operation}/wf_address_change`
          case '0000000019':
            return `/workflow/${operation}/wf_commuting_route_change`
          case '0000000020':
            return `/workflow/${operation}/wf_personal_information_change`
          default:
            return ''
        }
      },
      calcDays () {
        if (!this.application.term_from || !this.application.term_to) return
        const requestJson = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
            company_code: this.$store.state.loginUser.companyCode,
            group_code: this.applicationInfoDto.target_group_code,
            employee_code: this.applicationInfoDto.target_employee_code,
            term_from: this.application.term_from,
            term_to: this.application.term_to
          }
        }
        const url = this.applicationFormCode === '0000000008' ? '/workflow/view/wf_parental_leave/apply' : '/workflow/view/wf_holiday/apply'
        this.send(url, requestJson, false)
        .then(res => {
          this.application.holiday_days = res.api_area.work_days + this.$t('label.unit_days_between')
          if (!this.initData && !!res.api_area.application_data) {
            const application = {...this.application}
            switch (this.applicationFormCode) {
              case '0000000008':
                application.parental_leave_type = {
                  value: res.api_area.application_data.parentalLeave.parental_leave_type ? res.api_area.application_data.parentalLeave.parental_leave_type : res.api_area.application_data.parentalLeave.paid_holiday_type ? res.api_area.application_data.parentalLeave.paid_holiday_type : 1,
                  disabled: false,
                  items: this.application.parental_leave_type.items
                }
                if (application.parental_leave_type.value === 2) {
                  application.term_time_from_hour = !res.api_area.application_data.parentalLeave.term_time_from ? '' : String(parseInt(res.api_area.application_data.parentalLeave.term_time_from.split(':')[0], 10))
                  application.term_time_from_minute = !res.api_area.application_data.parentalLeave.term_time_from ? '' : res.api_area.application_data.parentalLeave.term_time_from.split(':')[1]
                  application.term_time_to_hour = !res.api_area.application_data.parentalLeave.term_time_to ? '' : String(parseInt(res.api_area.application_data.parentalLeave.term_time_to.split(':')[0], 10))
                  application.term_time_to_minute = !res.api_area.application_data.parentalLeave.term_time_to ? '' : res.api_area.application_data.parentalLeave.term_time_to.split(':')[1]
                }
                if (!application.term_time_from_hour) {
                  application.term_time_from_hour = '0'
                  application.term_time_from_minute = '00'
                  application.term_time_to_hour = '0'
                  application.term_time_to_minute = '00'
                }
                application.business_content = res.api_area.application_data.parentalLeave.contents
                this.application = application
                this.$refs.parentalLeave.componentKey += 1
                break
              case '0000000009':
              case '0000000010':
                application.paid_holiday_type = {
                  value: res.api_area.application_data.paidLeaveEmployee.paid_holiday_type ? res.api_area.application_data.paidLeaveEmployee.paid_holiday_type : 1,
                  disabled: false,
                  items: this.application.paid_holiday_type.items
                }
                if (application.paid_holiday_type.value === 2 || application.paid_holiday_type.value === 4) {
                  application.term_time_from_hour = String(parseInt(res.api_area.application_data.paidLeaveEmployee.term_time_from.split(':')[0], 10))
                  application.term_time_from_minute = res.api_area.application_data.paidLeaveEmployee.term_time_from.split(':')[1]
                  application.term_time_to_hour = String(parseInt(res.api_area.application_data.paidLeaveEmployee.term_time_to.split(':')[0], 10))
                  application.term_time_to_minute = res.api_area.application_data.paidLeaveEmployee.term_time_to.split(':')[1]
                }
                if (!application.term_time_from_hour) {
                  application.term_time_from_hour = '0'
                  application.term_time_from_minute = '00'
                  application.term_time_to_hour = '0'
                  application.term_time_to_minute = '00'
                }
                application.business_content = res.api_area.application_data.paidLeaveEmployee.contents
                this.application = application
                this.$refs.holiday.componentKey += 1
                break
            }
          } else {
            switch (this.applicationFormCode) {
              case '0000000008':
                this.$refs.parentalLeave.componentKey += 1
                break
              case '0000000009':
              case '0000000010':
                this.$refs.holiday.componentKey += 1
                break
            }
          }
        })
      },
      showApproverSearch (routeType) {
        this.selectedRouteType = routeType
        this.$modal.show('approver')
      },
      hideApproverSearch (selected) {
        this.$modal.hide('approver')
        if (this.selectedRouteType === '1') {
          this.$refs.individualRoute.hideApproverSearch(selected)
        } else {
          this.$refs.commonRoute.hideApproverSearch(selected)
        }
      },
      showAttendanceRecord () {
        const params = {
          orgSearchCond: {},
          headers: {},
          row: {
            term_from: this.application.term_from,
            term_to: this.application.term_to,
            employee_code: this.applicationInfoDto.target_employee_code,
            target_date: this.application.year + ('0' + this.application.month).slice(-2)
          },
          errors: this.attendanceRecordErrors
        }
        localStorage.setItem('params', JSON.stringify(params))
        if (this.isModal) {
          this.$emit('show-attendance-record')
        } else {
          this.$modal.show('attendanceRecord')
        }
      },
      hideAttendanceRecord () {
        this.$modal.hide('attendanceRecord')
      },
      showWorkflow () {
        if (!this.reapplicationRecord.application_number) {
          this.reapplicationRecord = {
            screen_mode: 7,
            company_code: this.applicationInfoDto.target_company_code,
            group_code: this.applicationInfoDto.target_group_code,
            employee_code: this.applicationInfoDto.target_employee_code,
            target_company_code: this.applicationInfoDto.target_company_code,
            target_group_code: this.applicationInfoDto.target_group_code,
            target_employee_code: this.applicationInfoDto.target_employee_code,
            approverl_company_code: this.applicationInfoDto.approverl_company_code,
            approverl_group_code: this.applicationInfoDto.approverl_group_code,
            approverl_employee_code: this.applicationInfoDto.approverl_employee_code,
            application_number: this.applicationInfoDto.re_application_number,
            route_type: this.applicationInfoDto.route_type,
            route_number: this.applicationInfoDto.route_number
          }
        }
        if (this.approveRecord) {
          this.$modal.show('approveWorkflow')
        } else {
          this.$modal.show('applyWorkflow')
        }
      },
      hideWorkflow () {
        if (this.approveRecord) {
          this.$modal.hide('approveWorkflow')
        } else {
          this.$modal.hide('applyWorkflow')
        }
      },
      showComment (row) {
        this.selectedComment = row.comment
        if (row.comment.indexOf(',') > -1) {
          const comments = row.comment.split(',')
          if (comments[0] === comments[1]) {
            this.selectedComment = comments[0]
          }
        }
        this.$modal.show('comment')
      },
      hideComment () {
        this.$modal.hide('comment')
      },
      applicationTypeChanged (applicationType) {
        if (this.applicationFormCode === '0000000005' || this.applicationFormCode === '0000000006') {
          if (applicationType === '1') {
            this.selectedApplicationFormCode = '0000000005'
          } else {
            this.selectedApplicationFormCode = '0000000006'
          }
        }
        this.initApply(this.application.target_date, this.application.work_schedule_code)
      },
      showActual () {
        const requestJson = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
            company_code: this.$store.state.loginUser.companyCode,
            group_code: this.applicationInfoDto.target_group_code,
            employee_code: this.applicationInfoDto.target_employee_code,
            target_date: this.application.target_date,
            work_schedule_code: this.application.work_schedule_code
          }
        }
        this.send(this.viewUrl, requestJson, false)
        .then(res => {
          switch (this.applicationFormCode) {
            case '0000000001':
              this.application.actual_start_end_time = ''
              if (res.api_area.stamping_start_time) {
                const startHours = String(parseInt(res.api_area.stamping_start_time.split(':')[0], 10))
                const startMinutes = res.api_area.stamping_start_time.split(':')[1]
                this.application.actual_start_end_time += `${this.$t('label.job_start')} ${startHours}${this.$t('label.hour')}${startMinutes}${this.$t('label.minute')}`
                this.application.start_hours = startHours
                this.application.start_minutes = startMinutes
              }
              if (res.api_area.stamping_end_time) {
                const endHours = parseInt(res.api_area.stamping_end_time.split(':')[0], 10)
                const endMinutes = res.api_area.stamping_end_time.split(':')[1]
                if (this.application.actual_start_end_time.length > 0) this.application.actual_start_end_time += ' / '
                this.application.actual_start_end_time += `${this.$t('label.job_end')} ${String(endHours)}${this.$t('label.hour')}${endMinutes}${this.$t('label.minute')}`
                if (endHours < 24) {
                  this.application.end_hours = String(endHours)
                  this.application.next_day = false
                } else {
                  this.application.end_hours = String(endHours - 24)
                  this.application.next_day = true
                }
                this.application.end_minutes = endMinutes
              }
              this.$refs.imprintCorrection.componentKey += 1
              break
            case '0000000002':
              if (res.api_area.overwork_minutes) {
                const overtimeHours = String(Math.floor(res.api_area.overwork_minutes / 60))
                const overtimeMinutes = ('0' + String(res.api_area.overwork_minutes % 60)).slice(-2)
                this.application.actual_overwork_minutes = `${overtimeHours}${this.$t('label.hours')}${overtimeMinutes}${this.$t('label.minute')}`
                this.application.overtime_hours = overtimeHours
                this.application.overtime_minutes = overtimeMinutes
              } else {
                this.application.actual_overwork_minutes = ''
              }
              this.$refs.overtime.componentKey += 1
              break
            case '0000000003':
              this.application.actual_ground = res.api_area.ground_data.label
              this.$refs.ground.componentKey += 1
              break
            case '0000000004':
              if (res.api_area.late_night_overwork_minutes) {
                const lateNightOvertimeHours = String(Math.floor(res.api_area.late_night_overwork_minutes / 60))
                const lateNightOvertimeMinutes = ('0' + String(res.api_area.late_night_overwork_minutes % 60)).slice(-2)
                this.application.actual_overwork_minutes = `${lateNightOvertimeHours}${this.$t('label.hours')}${lateNightOvertimeMinutes}${this.$t('label.minute')}`
                this.application.late_night_hours = lateNightOvertimeHours
                this.application.late_night_minutes = lateNightOvertimeMinutes
              } else {
                this.application.actual_overwork_minutes = ''
              }
              this.$refs.lateNightOverwork.componentKey += 1
              break
            case '0000000005':
            case '0000000006':
              if (this.application.late_early_departure_type.value === '1') {
                if (res.api_area.late_minutes) {
                  const lateHours = String(Math.floor(res.api_area.late_minutes / 60))
                  const lateMinutes = ('0' + String(res.api_area.late_minutes % 60)).slice(-2)
                  this.application.actual_late_early_minutes = `${lateHours}${this.$t('label.hours')}${lateMinutes}${this.$t('label.minute')}`
                  this.application.late_early_departure_hours = lateHours
                  this.application.late_early_departure_minutes = lateMinutes
                } else {
                  this.application.actual_late_early_minutes = ''
                }
              } else {
                if (res.api_area.early_departure_minutes) {
                  const earlyHours = String(Math.floor(res.api_area.early_departure_minutes / 60))
                  const earlyMinutes = ('0' + String(res.api_area.early_departure_minutes % 60)).slice(-2)
                  this.application.actual_late_early_minutes = `${earlyHours}${this.$t('label.hours')}${earlyMinutes}${this.$t('label.minute')}`
                  this.application.late_early_departure_hours = earlyHours
                  this.application.late_early_departure_minutes = earlyMinutes
                } else {
                  this.application.actual_late_early_minutes = ''
                }
              }
              this.$refs.lateEarlyDepartureTime.componentKey += 1
              break
            case '0000000007':
              const hours = String(Math.floor(res.api_area.holiday_work_hours / 60))
              const minutes = ('0' + String(res.api_area.holiday_work_hours % 60)).slice(-2)
              this.application.actual_holiday_minutes = `${hours}${this.$t('label.hours')}${minutes}${this.$t('label.minute')}`
              if (res.api_area.re_ground_data.label) {
                this.application.actual_holiday_minutes += ' / ' + res.api_area.re_ground_data.label
              }
              this.application.holiday_hours = hours
              this.application.holiday_minutes = minutes
              this.$refs.holidayWork.componentKey += 1
              break
          }
        })
      },
      updateRoute (params) {
        if (params) {
          this.send('/workflow/view/wf_approval_route', params)
          .then(res => {
            this.applicationInfoDto.approverl_list = []
            const individualRoute = []
            for (let route of res.api_area.individual_route) {
              let data = {
                route_order: route.route_order,
                company_code: route.company_code,
                company_name: route.company_name,
                group_code: route.group_code,
                group_name: route.group_name,
                employee_code: route.employee_code ? route.employee_code : route.role_code,
                employee_name: route.employee_name ? route.employee_name : route.role_name,
                auth_code: String(route.autholity),
                isFixed: true,
                groups: route.group_list
              }
              individualRoute.push(data)
              const approverlData = {
                company_code: this.$store.state.loginUser.companyCode,
                company_name: this.$store.state.loginUser.companyName,
                application_number: '',
                route_type: 1,
                route_number: route.route_order,
                approverl_company_code: route.company_code,
                approverl_company_name: route.company_name,
                approverl_role_code: route.role_code,
                approverl_role_name: route.role_name,
                approverl_group_code: route.group_code,
                approverl_group_name: route.group_name,
                approverl_employee_code: route.employee_code,
                approverl_employee_name: route.employee_name,
                deputy_approverl_company_code: '',
                deputy_approverl_company_name: '',
                deputy_approverl_group_code: '',
                deputy_approverl_group_name: '',
                deputy_approverl_employee_code: '',
                deputy_approverl_employee_name: '',
                deputy_contents: '',
                approval_function: parseInt(route.autholity, 10),
                reaching_date: '',
                process_date: '',
                activity_status: 0,
                approverl_comment: ''
              }
              this.applicationInfoDto.approverl_list.push(approverlData)
            }
            this.individualRoute = individualRoute
            const commonRoute = []
            for (let route of res.api_area.common_route) {
              let data = {
                route_order: route.route_order,
                company_code: route.company_code,
                company_name: route.company_name,
                group_code: route.group_code,
                group_name: route.group_name,
                employee_code: route.employee_code ? route.employee_code : route.role_code,
                employee_name: route.employee_name ? route.employee_name : route.role_name,
                auth_code: String(route.autholity),
                isFixed: true,
                groups: route.group_list
              }
              commonRoute.push(data)
              const approverlData = {
                company_code: this.$store.state.loginUser.companyCode,
                company_name: this.$store.state.loginUser.companyName,
                application_number: '',
                route_type: 2,
                route_number: route.route_order,
                approverl_company_code: route.company_code,
                approverl_company_name: route.company_name,
                approverl_role_code: route.role_code,
                approverl_role_name: route.role_name,
                approverl_group_code: route.group_code,
                approverl_group_name: route.group_name,
                approverl_employee_code: route.employee_code,
                approverl_employee_name: route.employee_name,
                deputy_approverl_company_code: '',
                deputy_approverl_company_name: '',
                deputy_approverl_group_code: '',
                deputy_approverl_group_name: '',
                deputy_approverl_employee_code: '',
                deputy_approverl_employee_name: '',
                deputy_contents: '',
                approval_function: parseInt(route.autholity, 10),
                reaching_date: '',
                process_date: '',
                activity_status: 0,
                approverl_comment: ''
              }
              this.applicationInfoDto.approverl_list.push(approverlData)
            }
            this.commonRoute = commonRoute
          }).catch(() => {
            this.applicationInfoDto.boss_group_code = ''
            this.applicationInfoDto.boss_group_name = ''
            this.applicationInfoDto.boss_employee_code = ''
            this.applicationInfoDto.boss_employee_name = ''
            this.componentKey += 1
          })
        } else {
          this.applicationInfoDto.approverl_list = []
          this.individualRoute = []
          this.commonRoute = []
        }
      },
      execSubmitApplication (operation) {
        if (!this.applicationInfoDto.target_group_code || !this.applicationInfoDto.target_employee_code) {
          swal({
            title: this.$t('message.applicant_not_found'),
            html: this.$t('message.applicant_not_found_description'),
            type: 'error',
            confirmButtonClass: 'btn btn-warning btn-sm btn-default',
            buttonsStyling: false,
            allowOutsideClick: false
          })
          return
        }
        this.$refs.observer.validate().then(isValid => {
          this.errMsgs1 = []
          let copy1Data = []
          for (let data of this.individualRoute) {
            copy1Data.push(Object.assign({}, data, {}))
          }
          let isExaminationExist = false
          for (let i = 0; i < copy1Data.length; i++) {
            if (copy1Data[i].selected) continue
            let data = copy1Data[i]
            data.isGroupError = false
            data.isEmployeeError = false
            data.isAuthError = false
            if (!data.employee_name || data.employee_name.length === 0) {
              data.isEmployeeError = true
              this.errMsgs1.push(this.$t('message.is_item_required_for_row').replace('{row}', (i + 1)).replace('{item}', this.labels.approverl_employee_role ? this.labels.approverl_employee_role : '承認者 / ロール'))
            }
            if (!data.auth_code || data.auth_code.length === 0) {
              data.isAuthError = true
              this.errMsgs1.push(this.$t('message.is_item_required_for_row').replace('{row}', (i + 1)).replace('{item}', this.labels.label_authority))
            }
            if (data.auth_code === '1') isExaminationExist = true
          }
          this.errMsgs2 = []
          let copy2Data = []
          for (let data of this.commonRoute) {
            copy2Data.push(Object.assign({}, data, {}))
          }
          var isKakunin = false
          for (let i = 0; i < copy2Data.length; i++) {
            if (copy2Data[i].selected) continue
            let data = copy2Data[i]
            data.isGroupError = false
            data.isEmployeeError = false
            data.isAuthError = false
            if (!data.employee_name || data.employee_name.length === 0) {
              data.isEmployeeError = true
              this.errMsgs2.push(this.$t('message.is_item_required_for_row').replace('{row}', (i + 1)).replace('{item}', this.labels.approverl_employee_role ? this.labels.approverl_employee_role : '承認者 / ロール'))
            }
            if (!data.auth_code || data.auth_code.length === 0) {
              data.isAuthError = true
              this.errMsgs2.push(this.$t('message.is_item_required_for_row').replace('{row}', (i + 1)).replace('{item}', this.labels.label_authority))
            }
            if (data.auth_code === '3') {
              isKakunin = true
            }
            if (isKakunin && data.auth_code === '1') {
              this.errMsgs2.push(this.$t('message.unselectable_authority'))
            }
            if (data.auth_code === '1') isExaminationExist = true
          }
          if (!isExaminationExist) {
            this.errMsgs1.push(this.$t('message.is_examination_required_workflow'))
          }
          if (copy1Data.length + copy2Data.length === 0) {
            this.errMsgs1.push(this.$t('message.is_approval_route_required').replace('{item4}', this.$t('label.approval_route')))
          }
          this.errMsgs3 = []
          switch (this.applicationFormCode) {
            case '0000000001':
              if (this.applicationInfoDto.screen_mode !== '2' &&
                this.application.org_telework_flg === this.application.telework_flg &&
                (!this.application.apply_items.items[0].value || (this.application.org_start_hours === this.application.start_hours &&
                this.application.org_start_minutes === this.application.start_minutes)) &&
                (!this.application.apply_items.items[1].value || (this.application.org_end_hours === this.application.end_hours &&
                this.application.org_end_minutes === this.application.end_minutes))) {
                let isSame = true
                for (const breakTime of this.application.break_time_list) {
                  if (breakTime.start_time !== breakTime.re_start_time || breakTime.end_time !== breakTime.re_end_time || breakTime.del_flag) {
                    isSame = false
                    break
                  }
                }
                if (isSame) {
                  this.errMsgs3.push(this.$t('message.no_difference_application'))
                  isValid = false
                }
              } else if (this.application.org_start_hours && this.application.org_start_minutes &&
                this.application.org_end_hours && this.application.org_end_minutes &&
                this.application.apply_items.items[0].value && !this.application.start_hours && !this.application.start_minutes &&
                (!this.application.apply_items.items[1].value || (this.application.apply_items.items[1].value && this.application.end_hours && this.application.end_minutes))
              ) {
                this.errMsgs3.push(this.$t('message.cannot_delete_start_minutes'))
                isValid = false
              }
              let row = 1
              let breakTimeListErrors = []
              for (const breakTime of this.application.break_time_list) {
                if (!breakTime.del_flag && (breakTime.start_time || breakTime.end_time)) {
                  // 休憩時間チェック
                  // 開始時間hh:mm形式チェック（48:00まで）
                  if (!!breakTime.start_time && !breakTime.start_time.match(/^([0-9]|[0-3][0-9]|4[0-7]):([0-5][0-9])$/)) {
                    if (breakTime.start_time !== '48:00') {
                      breakTimeListErrors.push(this.$t('message.row_start_time_error').replace('{row}', row))
                    }
                  }
                  // 終了時間hh:mm形式チェック（48:00まで）
                  if (!!breakTime.end_time && !breakTime.end_time.match(/^([0-9]|[0-3][0-9]|4[0-7]):([0-5][0-9])$/)) {
                    if (breakTime.end_time !== '48:00') {
                      breakTimeListErrors.push(this.$t('message.row_end_time_error').replace('{row}', row))
                    }
                  }
                }
                row++
              }
              if (breakTimeListErrors.length > 0) {
                this.application.breakTimeListErrors = breakTimeListErrors
                isValid = false
              }
              break
            case '0000000002':
              const overworkMinutes = parseInt(this.application.overtime_hours, 10) * 60 + parseInt(this.application.overtime_minutes, 10)
              if (overworkMinutes === 0) {
                this.errMsgs3.push(this.$t('message.invalid_overtime'))
                isValid = false
              }
              break
            case '0000000004':
              const lateNightOverworkMinutes = parseInt(this.application.late_night_overwork_minutes, 10)
              if (lateNightOverworkMinutes === 0) {
                this.errMsgs3.push(this.$t('message.invalid_late_night_overtime'))
                isValid = false
              }
              break
            case '0000000007':
              const holidayWorkHours = parseInt(this.application.holiday_minutes, 10)
              if (holidayWorkHours === 0) {
                this.errMsgs3.push(this.$t('message.invalid_holiday_work_hours'))
                isValid = false
              }
              break
            case '0000000008':
              if (this.application.parental_leave_type.value === 2) {
                const termTimeFrom = parseInt(this.application.term_time_from_hour, 10) * 60 + parseInt(this.application.term_time_from_minute, 10)
                const termTimeTo = parseInt(this.application.term_time_to_hour, 10) * 60 + parseInt(this.application.term_time_to_minute, 10)
                if (termTimeTo - termTimeFrom === 0) {
                  this.errMsgs3.push(this.$t('message.invalid_parental_leave_hours'))
                  isValid = false
                }
              }
              break
            case '0000000009':
            case '0000000010':
              if (this.application.paid_holiday_type.value === 2 || this.application.paid_holiday_type.value === 4) {
                const termTimeFrom = parseInt(this.application.term_time_from_hour, 10) * 60 + parseInt(this.application.term_time_from_minute, 10)
                const termTimeTo = parseInt(this.application.term_time_to_hour, 10) * 60 + parseInt(this.application.term_time_to_minute, 10)
                if (termTimeTo - termTimeFrom === 0) {
                  this.errMsgs3.push(this.$t('message.invalid_paid_holiday_hours'))
                  isValid = false
                }
              }
              break
            case '0000000018':
              const stateName = this.getSelectName(this.application.after_prefectures, this.comboData.prefectures_list)
              const municipalityName = this.getSelectName(this.application.after_city, this.comboData.municipality_list)
              if (this.application.before_post_code === this.application.after_post_code &&
                  this.application.before_prefectures === stateName &&
                  this.application.before_city === municipalityName &&
                  this.application.before_town === this.application.after_town &&
                  this.application.before_building === this.application.after_building &&
                  this.application.before_tel === this.application.after_tel &&
                  this.application.before_emergency_contact === this.application.after_emergency_contact &&
                  this.application.before_other === this.application.after_other &&
                  (!this.application.append_path_list && this.application.append_path_list.length === 0)
                ) {
                this.errMsgs3.push(this.$t('message.no_difference_application'))
                isValid = false
              }
              break
            case '0000000019':
              for (let i = 0; i < this.application.after.length; i++) {
                const row = this.application.after[i]
                if (row.selected) continue
                if (!row.traffic_division) {
                  this.errMsgs3.push(this.$t('message.is_item_required_for_row').replace('{row}', i + 1).replace('{item}', this.labels.traffic_division))
                  isValid = false
                } else if (row.traffic_division === '1') {
                  if (!row.transportation_name) {
                    this.errMsgs3.push(this.$t('message.is_item_required_for_row').replace('{row}', i + 1).replace('{item}', this.labels.transportation_name))
                    isValid = false
                  }
                  if (!row.start_section) {
                    this.errMsgs3.push(this.$t('message.is_item_required_for_row').replace('{row}', i + 1).replace('{item}', this.labels.start_section))
                    isValid = false
                  }
                  if (!row.end_section) {
                    this.errMsgs3.push(this.$t('message.is_item_required_for_row').replace('{row}', i + 1).replace('{item}', this.labels.end_section))
                    isValid = false
                  }
                } else if (row.traffic_division === '3') {
                  if (!row.distance_to_use_transportation_equipment) {
                    this.errMsgs3.push(this.$t('message.is_item_required_for_row').replace('{row}', i + 1).replace('{item}', this.labels.distance_to_use_transportation_equipment))
                    isValid = false
                  }
                }
                if (!row.payment_unit) {
                  this.errMsgs3.push(this.$t('message.is_item_required_for_row').replace('{row}', i + 1).replace('{item}', this.labels.payment_unit))
                  isValid = false
                }
                if (!row.payment_amount) {
                  this.errMsgs3.push(this.$t('message.is_item_required_for_row').replace('{row}', i + 1).replace('{item}', this.labels.payment_amount))
                  isValid = false
                } else if (isNaN(row.payment_amount)) {
                  this.errMsgs3.push(this.$t('message.is_item_number_for_row').replace('{row}', i + 1).replace('{item}', this.labels.payment_amount))
                  isValid = false
                }
              }
              break
            case '0000000020':
              if (this.application.before_employee_name === this.application.after_employee_name &&
                  this.application.before_mail_address === this.application.after_mail_address &&
                  String(this.application.before_sex) === String(this.application.after_sex)
                ) {
                this.errMsgs3.push(this.$t('message.no_difference_application'))
                isValid = false
              }
              break
          }
          this.errMsgs4 = []
          if (!isValid || this.errMsgs1.length > 0 || this.errMsgs2.length > 0) {
            this.individualRoute = copy1Data
            this.commonRoute = copy2Data
            this.$emit('on-submit', this.registerForm, isValid)
            if (this.isModal) {
              this.$refs.frameModal.scrollTop = 0
            } else {
              this.$refs.frame.parentNode.parentNode.parentNode.scrollTop = 0
            }
            return
          }
          if (this.applicationFormCode === '0000000016') {
            if (this.application.is_absent_popup) {
              swal({
                text: this.$t('message.confirm_to_replace_ground'),
                buttonsStyling: false,
                confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
                cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
                type: 'warning',
                showCancelButton: true,
                reverseButtons: true,
                confirmButtonText: this.$t('button.yes'),
                cancelButtonText: this.$t('button.no'),
                width: 550
              }).then(() => {
                this.editApplicationInfoDto()
                this.apply(operation)
              })
              return
            }
          }
          this.editApplicationInfoDto()
          this.apply(operation)
        })
      },
      editApplicationInfoDto () {
        for (let approver of this.applicationInfoDto.approverl_list) {
          approver.approverl_comment = 'test'
        }
        switch (this.applicationFormCode) {
          case '0000000001':
            this.applicationInfoDto.target_date = this.formatDate(this.application.target_date)
            this.applicationInfoDto.work_schedule_code = this.application.work_schedule_code
            this.applicationInfoDto.telework_flg = this.application.telework_flg
            if (this.application.start_hours && this.application.start_minutes) {
              const startImprintCorrectionTime = ('0' + this.application.start_hours).slice(-2) + ':' + this.application.start_minutes
              this.applicationInfoDto.stamping_start_time = startImprintCorrectionTime
            } else {
              this.applicationInfoDto.stamping_start_time = ''
            }
            if (this.application.end_hours && this.application.end_minutes) {
              let endHours = parseInt(this.application.end_hours, 10)
              if (this.application.next_day) {
                endHours += 24
              }
              const endImprintCorrectionTime = ('0' + String(endHours)).slice(-2) + ':' + this.application.end_minutes
              this.applicationInfoDto.stamping_end_time = endImprintCorrectionTime
            } else {
              this.applicationInfoDto.stamping_end_time = ''
            }
            this.applicationInfoDto.break_time_list = []
            for (const row of this.application.break_time_list) {
              if (row.start_time) row.start_time = ('0' + row.start_time).slice(-5)
              if (row.end_time) row.end_time = ('0' + row.end_time).slice(-5)
              if (row.re_start_time) row.re_start_time = ('0' + row.re_start_time).slice(-5)
              if (row.re_end_time) row.re_end_time = ('0' + row.re_end_time).slice(-5)
              this.applicationInfoDto.break_time_list.push(row)
            }
            this.applicationInfoDto.imprint_correction_content = this.application.business_content ? this.application.business_content : ''
            this.applicationInfoDto.supplement = this.application.supplement ? this.application.supplement : ''
            this.applicationInfoDto.select_check = {
              check_start_time: this.application.apply_items.items[0].value,
              check_end_time: this.application.apply_items.items[1].value,
              check_telework: this.application.apply_items.items[2].value
            }
            break
          case '0000000002':
            this.applicationInfoDto.target_date = this.formatDate(this.application.target_date)
            const overworkMinutes = parseInt(this.application.overtime_hours, 10) * 60 + parseInt(this.application.overtime_minutes, 10)
            this.applicationInfoDto.work_schedule_code = this.application.work_schedule_code
            this.applicationInfoDto.overwork_minutes = overworkMinutes
            this.applicationInfoDto.reasons_over_work_contents = this.application.reasons_over_work_contents
            this.applicationInfoDto.business_content = this.application.business_content ? this.application.business_content : ''
            this.applicationInfoDto.supplement = this.application.supplement ? this.application.supplement : ''
            break
          case '0000000003':
            this.applicationInfoDto.confirm_day = this.formatDate(this.application.target_date)
            // this.applicationInfoDto.work_schedule_code = this.application.work_schedule_code
            this.applicationInfoDto.ground_code = this.application.ground_code ? this.application.ground_code : ''
            this.applicationInfoDto.contents = this.application.supplement ? this.application.supplement : ''
            break
          case '0000000004':
            this.applicationInfoDto.target_date = this.formatDate(this.application.target_date)
            this.applicationInfoDto.work_schedule_code = this.application.work_schedule_code
            this.applicationInfoDto.late_night_overwork_minutes = parseInt(this.application.late_night_overwork_minutes, 10)
            this.applicationInfoDto.business_content = this.application.business_content ? this.application.business_content : ''
            this.applicationInfoDto.supplement = this.application.supplement ? this.application.supplement : ''
            break
          case '0000000005':
          case '0000000006':
            this.applicationInfoDto.target_date = this.formatDate(this.application.target_date)
            const lateEarlyDepartureMinutes = parseInt(this.application.late_early_departure_minutes, 10)
            this.applicationInfoDto.work_schedule_code = this.application.work_schedule_code
            this.applicationInfoDto.late_minutes = this.application.late_early_departure_type.value === '1' ? lateEarlyDepartureMinutes : null
            this.applicationInfoDto.late_content = this.application.late_early_departure_type.value === '1' ? this.application.business_content : ''
            this.applicationInfoDto.early_departure_minutes = this.application.late_early_departure_type.value === '2' ? lateEarlyDepartureMinutes : null
            this.applicationInfoDto.early_departure_content = this.application.late_early_departure_type.value === '2' ? this.application.business_content : ''
            this.applicationInfoDto.supplement = this.application.supplement ? this.application.supplement : ''
            // this.applicationInfoDto.is_late_time_application = this.application.late_early_departure_type.value === '1'
            break
          case '0000000007':
            this.applicationInfoDto.target_date = this.formatDate(this.application.target_date)
            this.applicationInfoDto.work_schedule_code = this.application.work_schedule_code
            this.applicationInfoDto.holiday_work_hours = parseInt(this.application.holiday_minutes, 10)
            this.applicationInfoDto.business_content = this.application.business_content ? this.application.business_content : ''
            this.applicationInfoDto.supplement = this.application.supplement ? this.application.supplement : ''
            break
          case '0000000008':
            this.applicationInfoDto.parental_leave_type = parseInt(this.application.parental_leave_type.value, 10)
            this.applicationInfoDto.term_from = this.formatDate(this.application.term_from)
            this.applicationInfoDto.term_to = this.applicationInfoDto.parental_leave_type === 2 ? this.applicationInfoDto.term_from : this.formatDate(this.application.term_to)
            if (this.applicationInfoDto.parental_leave_type === 2) {
              const termTimeFrom = ('0' + this.application.term_time_from_hour).slice(-2) + ':' + this.application.term_time_from_minute
              this.applicationInfoDto.term_time_from = termTimeFrom
              const termTimeTo = ('0' + this.application.term_time_to_hour).slice(-2) + ':' + this.application.term_time_to_minute
              this.applicationInfoDto.term_time_to = termTimeTo
            } else {
              this.applicationInfoDto.term_time_from = ''
              this.applicationInfoDto.term_time_to = ''
            }
            this.applicationInfoDto.contents = this.application.business_content ? this.application.business_content : ''
            break
          case '0000000009':
          case '0000000010':
            this.applicationInfoDto.paid_holiday_type = parseInt(this.application.paid_holiday_type.value, 10)
            this.applicationInfoDto.term_from = this.formatDate(this.application.term_from)
            this.applicationInfoDto.term_to = this.applicationInfoDto.paid_holiday_type === 2 ? this.applicationInfoDto.term_from : this.formatDate(this.application.term_to)
            if (this.applicationInfoDto.paid_holiday_type === 2 || this.applicationInfoDto.paid_holiday_type === 4) {
              const termTimeFrom = ('0' + this.application.term_time_from_hour).slice(-2) + ':' + this.application.term_time_from_minute
              this.applicationInfoDto.term_time_from = termTimeFrom
              const termTimeTo = ('0' + this.application.term_time_to_hour).slice(-2) + ':' + this.application.term_time_to_minute
              this.applicationInfoDto.term_time_to = termTimeTo
            } else {
              this.applicationInfoDto.term_time_from = ''
              this.applicationInfoDto.term_time_to = ''
            }
            this.applicationInfoDto.contents = this.application.business_content ? this.application.business_content : ''
            break
          case '0000000011':
          case '0000000012':
            // this.applicationInfoDto.work_schedule_code = this.application.work_schedule_code
            this.applicationInfoDto.transfer_work_date = this.formatDate(this.application.transfer_work_date)
            this.applicationInfoDto.transfer_holiday_date = this.formatDate(this.application.transfer_holiday_date)
            this.applicationInfoDto.business_content = this.application.business_content ? this.application.business_content : ''
            this.applicationInfoDto.supplement = this.application.supplement ? this.application.supplement : ''
            break
          case '0000000013':
            this.applicationInfoDto.job_month = this.application.job_month
            this.applicationInfoDto.need_to_exceed = this.application.need_to_exceed ? this.application.need_to_exceed : ''
            this.applicationInfoDto.business_content = this.application.business_content ? this.application.business_content : ''
            this.applicationInfoDto.supplement = this.application.supplement ? this.application.supplement : ''
            break
          case '0000000014':
          case '0000000015':
            this.applicationInfoDto.term_from = this.formatDate(this.application.leave_job_start_date)
            this.applicationInfoDto.term_to = this.formatDate(this.application.leave_job_end_date)
            this.applicationInfoDto.append_list = this.application.append_list
            this.applicationInfoDto.contents = this.application.leave_job_content ? this.application.leave_job_content : ''
            this.applicationInfoDto.supplement = this.application.supplement ? this.application.supplement : ''
            break
          case '0000000016':
            this.applicationInfoDto.job_month = this.application.year + ('0' + this.application.month).slice(-2)
            break
          case '0000000018':
            this.applicationInfoDto.post_code = this.application.after_post_code
            this.applicationInfoDto.state_code = this.application.after_prefectures
            this.applicationInfoDto.municipality_code = this.application.after_city
            this.applicationInfoDto.town = this.application.after_town
            this.applicationInfoDto.building = this.application.after_building
            this.applicationInfoDto.tel = this.application.after_tel
            this.applicationInfoDto.emergency_contact = this.application.after_emergency_contact
            this.applicationInfoDto.other = this.application.after_other
            const appendPathList = []
            if (this.application.append_path_list) {
              for (const row of this.application.append_path_list) {
                // const appendPath = row.indexOf('tmp') === -1 ? null : row
                appendPathList.push({
                  append_path: row // appendPath
                })
              }
            }
            this.applicationInfoDto.append_path_list = appendPathList
            this.applicationInfoDto.supplement = this.application.supplement ? this.application.supplement : ''
            break
          case '0000000019':
            const commutingRouteChangeDetailList = []
            let serialNumber = 1
            for (const row of this.application.after) {
              if (row.selected) continue
              commutingRouteChangeDetailList.push({
                serial_number: serialNumber,
                traffic_division: row.traffic_division ? parseInt(row.traffic_division, 10) : null,
                distance_to_use_transportation_equipment: row.distance_to_use_transportation_equipment ? parseInt(row.distance_to_use_transportation_equipment, 10) : null,
                payment_unit: row.payment_unit ? parseInt(row.payment_unit, 10) : null,
                target_month: row.target_date ? parseInt(row.target_date, 10) : null,
                payment_amount: row.payment_amount ? parseInt(row.payment_amount, 10) : null,
                transportation_name: row.transportation_name,
                start_section: row.start_section,
                end_section: row.end_section
              })
              serialNumber += 1
            }
            this.applicationInfoDto.commuting_route_change_detail_list = commutingRouteChangeDetailList
            this.applicationInfoDto.supplement = this.application.supplement ? this.application.supplement : ''
            break
          case '0000000020':
            this.applicationInfoDto.employee_name = this.application.after_employee_name
            this.applicationInfoDto.mail_address = this.application.after_mail_address
            this.applicationInfoDto.sex = parseInt(this.application.after_sex, 10)
            this.applicationInfoDto.supplement = this.application.supplement ? this.application.supplement : ''
            break
        }
        let newRowIndex = 1
        let newRowCount = 0
        for (const route of this.individualRoute) {
          if (route.route_order === '' && !route.selected) {
            newRowCount = newRowCount + 1
          }
        }
        for (const route of this.applicationInfoDto.approverl_list) {
          if (route.route_type === 1) {
            route.route_number = route.route_number + newRowCount
          }
        }
        for (const route of this.individualRoute) {
          if (route.route_order === '' && !route.selected) {
            const data = {
              company_code: this.$store.state.loginUser.companyCode,
              company_name: this.$store.state.loginUser.companyName,
              application_number: '',
              route_type: 1,
              route_number: newRowIndex,
              approverl_company_code: route.company_code,
              approverl_company_name: route.company_name,
              approverl_role_code: route.role_code,
              approverl_role_name: route.role_name,
              approverl_group_code: route.group_code,
              approverl_group_name: route.group_name,
              approverl_employee_code: route.employee_code,
              approverl_employee_name: route.employee_name,
              deputy_approverl_company_code: '',
              deputy_approverl_company_name: '',
              deputy_approverl_group_code: '',
              deputy_approverl_group_name: '',
              deputy_approverl_employee_code: '',
              deputy_approverl_employee_name: '',
              deputy_contents: '',
              approval_function: parseInt(route.auth_code, 10),
              reaching_date: '',
              process_date: '',
              activity_status: 0,
              approverl_comment: ''
            }
            this.applicationInfoDto.approverl_list.push(data)
            newRowIndex = newRowIndex + 1
          }
        }
        newRowIndex = 1
        newRowCount = 0
        for (const route of this.commonRoute) {
          if (route.route_order === '' && !route.selected) {
            newRowCount = newRowCount + 1
          }
        }
        for (const route of this.applicationInfoDto.approverl_list) {
          if (route.route_type === 2) {
            route.route_number = route.route_number + newRowCount
          }
        }
        for (const route of this.commonRoute) {
          if (route.route_order === '' && !route.selected) {
            const data = {
              company_code: this.$store.state.loginUser.companyCode,
              company_name: this.$store.state.loginUser.companyName,
              application_number: '',
              route_type: 2,
              route_number: newRowIndex,
              approverl_company_code: route.company_code,
              approverl_company_name: route.company_name,
              approverl_role_code: route.role_code,
              approverl_role_name: route.role_name,
              approverl_group_code: route.group_code,
              approverl_group_name: route.group_name,
              approverl_employee_code: route.employee_code,
              approverl_employee_name: route.employee_name,
              deputy_approverl_company_code: '',
              deputy_approverl_company_name: '',
              deputy_approverl_group_code: '',
              deputy_approverl_group_name: '',
              deputy_approverl_employee_code: '',
              deputy_approverl_employee_name: '',
              deputy_contents: '',
              approval_function: route.auth_code,
              reaching_date: '',
              process_date: '',
              activity_status: 0,
              approverl_comment: 'test'
            }
            this.applicationInfoDto.approverl_list.push(data)
            newRowIndex = newRowIndex + 1
          }
        }
      },
      apply (operation, popupFlag = undefined) {
        this.isChanging = true
        const approverlList = []
        for (const row of this.applicationInfoDto.approverl_list) {
          const approverl = {
            route_type: row.route_type,
            route_number: row.route_number,
            approverl_company_code: this.emptyToBlank(row.approverl_company_code),
            approverl_company_name: row.approverl_company_name,
            approverl_role_code: this.emptyToBlank(row.approverl_role_code),
            approverl_role_name: this.emptyToBlank(row.approverl_role_name),
            approverl_group_code: this.emptyToBlank(row.approverl_group_code),
            approverl_group_name: this.emptyToBlank(row.approverl_group_name),
            approverl_employee_code: this.emptyToBlank(row.approverl_employee_code),
            approverl_employee_name: this.emptyToBlank(row.approverl_employee_name),
            deputy_approverl_company_code: this.emptyToBlank(row.deputy_approverl_company_code),
            deputy_approverl_company_name: this.emptyToBlank(row.deputy_approverl_company_name),
            deputy_approverl_group_code: this.emptyToBlank(row.deputy_approverl_group_code),
            deputy_approverl_group_name: this.emptyToBlank(row.deputy_approverl_group_name),
            deputy_approverl_employee_code: this.emptyToBlank(row.deputy_approverl_employee_code),
            deputy_approverl_employee_name: this.emptyToBlank(row.deputy_approverl_employee_name),
            deputy_contents: this.emptyToBlank(row.deputy_contents),
            approval_function: parseInt(row.approval_function, 10)
          }
          approverlList.push(approverl)
        }
        let url = null
        let apiArea = null
        switch (this.applicationFormCode) {
          case '0000000001':
            url = `/workflow/${operation}/wf_imprint_correction`
            apiArea = {
              target_date: this.applicationInfoDto.target_date,
              work_schedule_code: this.applicationInfoDto.work_schedule_code,
              telework_flg: this.applicationInfoDto.telework_flg,
              stamping_start_time: this.applicationInfoDto.stamping_start_time,
              stamping_end_time: this.applicationInfoDto.stamping_end_time,
              break_time_list: this.applicationInfoDto.break_time_list,
              imprint_correction_content: this.applicationInfoDto.imprint_correction_content,
              supplement: this.applicationInfoDto.supplement
            }
            this.isChanging = false
            if (!this.isReadOnly && operation !== 'cancel') {
              apiArea.select_check = this.applicationInfoDto.select_check
            }
            this.isChanging = true
            break
          case '0000000002':
            url = `/workflow/${operation}/wf_overtime`
            apiArea = {
              target_date: this.applicationInfoDto.target_date,
              work_schedule_code: this.applicationInfoDto.work_schedule_code,
              overwork_minutes: this.applicationInfoDto.overwork_minutes,
              reasons_over_work_contents: this.applicationInfoDto.reasons_over_work_contents,
              business_content: this.applicationInfoDto.business_content,
              supplement: this.applicationInfoDto.supplement
            }
            break
          case '0000000003':
            url = `/workflow/${operation}/wf_ground_confirm_employee`
            apiArea = {
              confirm_day: this.applicationInfoDto.confirm_day,
              // work_schedule_code: this.applicationInfoDto.work_schedule_code,
              ground_code: this.applicationInfoDto.ground_code,
              contents: this.applicationInfoDto.contents
            }
            break
          case '0000000004':
            url = `/workflow/${operation}/wf_late_night_overwork`
            apiArea = {
              target_date: this.applicationInfoDto.target_date,
              work_schedule_code: this.applicationInfoDto.work_schedule_code,
              late_night_overwork_minutes: this.applicationInfoDto.late_night_overwork_minutes,
              business_content: this.applicationInfoDto.business_content,
              supplement: this.applicationInfoDto.supplement
            }
            break
          case '0000000005':
          case '0000000006':
            url = `/workflow/${operation}/wf_late_time_early_departure_time`
            apiArea = {
              target_date: this.applicationInfoDto.target_date,
              work_schedule_code: this.applicationInfoDto.work_schedule_code,
              late_minutes: this.applicationInfoDto.late_minutes,
              late_content: this.applicationInfoDto.late_content,
              early_departure_minutes: this.applicationInfoDto.early_departure_minutes,
              early_departure_content: this.applicationInfoDto.early_departure_content,
              supplement: this.applicationInfoDto.supplement,
              application_form_code: this.selectedApplicationFormCode
            }
            break
          case '0000000007':
            url = `/workflow/${operation}/wf_holiday_work`
            apiArea = {
              target_date: this.applicationInfoDto.target_date,
              work_schedule_code: this.applicationInfoDto.work_schedule_code,
              holiday_work_hours: this.applicationInfoDto.holiday_work_hours,
              business_content: this.applicationInfoDto.business_content,
              supplement: this.applicationInfoDto.supplement
            }
            break
          case '0000000008':
            url = `/workflow/${operation}/wf_parental_leave`
            apiArea = {
              parental_leave_type: this.applicationInfoDto.parental_leave_type,
              term_from: this.applicationInfoDto.term_from,
              term_to: this.applicationInfoDto.term_to,
              term_time_from: this.applicationInfoDto.term_time_from,
              term_time_to: this.applicationInfoDto.term_time_to,
              contents: this.applicationInfoDto.contents
            }
            break
          case '0000000009':
          case '0000000010':
            url = `/workflow/${operation}/wf_holiday`
            apiArea = {
              paid_holiday_type: this.applicationInfoDto.paid_holiday_type,
              term_from: this.applicationInfoDto.term_from,
              term_to: this.applicationInfoDto.term_to,
              term_time_from: this.applicationInfoDto.term_time_from,
              term_time_to: this.applicationInfoDto.term_time_to,
              contents: this.applicationInfoDto.contents
            }
            break
          case '0000000011':
          case '0000000012':
            url = `/workflow/${operation}/wf_transfer_holiday_work`
            apiArea = {
              // work_schedule_code: this.applicationInfoDto.work_schedule_code,
              transfer_work_date: this.applicationInfoDto.transfer_work_date,
              transfer_holiday_date: this.applicationInfoDto.transfer_holiday_date,
              business_content: this.applicationInfoDto.business_content,
              supplement: this.applicationInfoDto.supplement
            }
            break
          case '0000000013':
            url = `/workflow/${operation}/wf_time_limit_exceeded`
            apiArea = {
              job_month: this.applicationInfoDto.job_month,
              need_to_exceed: this.applicationInfoDto.need_to_exceed,
              business_content: this.applicationInfoDto.business_content,
              supplement: this.applicationInfoDto.supplement
            }
            break
          case '0000000014':
          case '0000000015':
            url = `/workflow/${operation}/wf_leave_job`
            apiArea = {
              term_from: this.applicationInfoDto.term_from,
              term_to: this.applicationInfoDto.term_to,
              append_list: this.applicationInfoDto.append_list,
              contents: this.applicationInfoDto.contents,
              supplement: this.applicationInfoDto.supplement
            }
            break
          case '0000000016':
            url = `/workflow/${operation}/wf_attendance_record`
            apiArea = {
              job_month: this.applicationInfoDto.job_month,
              popup_flag: popupFlag
            }
            break
          case '0000000018':
            url = `/workflow/${operation}/wf_address_change`
            apiArea = {
              post_code: this.applicationInfoDto.post_code,
              state_code: this.applicationInfoDto.state_code,
              municipality_code: this.applicationInfoDto.municipality_code,
              town: this.applicationInfoDto.town,
              building: this.applicationInfoDto.building,
              tel: this.applicationInfoDto.tel,
              emergency_contact: this.applicationInfoDto.emergency_contact,
              other: this.applicationInfoDto.other,
              append_path_list: this.applicationInfoDto.append_path_list,
              supplement: this.applicationInfoDto.supplement
            }
            break
          case '0000000019':
            url = `/workflow/${operation}/wf_commuting_route_change`
            apiArea = {
              commuting_route_change_detail_list: this.applicationInfoDto.commuting_route_change_detail_list,
              supplement: this.applicationInfoDto.supplement
            }
            break
          case '0000000020':
            url = `/workflow/${operation}/wf_personal_information_change`
            apiArea = {
              employee_name: this.applicationInfoDto.employee_name,
              mail_address: this.applicationInfoDto.mail_address,
              sex: this.applicationInfoDto.sex,
              supplement: this.applicationInfoDto.supplement
            }
            break
        }
        apiArea.application_number = this.applicationInfoDto.application_number ? parseInt(this.applicationInfoDto.application_number, 10) : null
        apiArea.re_application_number = this.applicationInfoDto.re_application_number ? parseInt(this.applicationInfoDto.re_application_number, 10) : null
        apiArea.screen_mode = parseInt(this.applicationInfoDto.screen_mode, 10)
        apiArea.target_group_code = this.applicationInfoDto.target_group_code
        apiArea.target_group_name = this.applicationInfoDto.target_group_name
        apiArea.target_employee_code = this.applicationInfoDto.target_employee_code
        apiArea.target_employee_name = this.applicationInfoDto.target_employee_name
        apiArea.approverl_list = approverlList
        const params = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: apiArea
        }
        let isOverwrite = true
        let isForceSuccess = false
        if (this.isModal && this.applicationFormCode === '0000000016' && !this.approveRecord) {
          isForceSuccess = true
        }
        this.send(url, params, isOverwrite, isForceSuccess)
        .then(res => {
          if (res.api_area && res.api_area.message && res.api_area.correspondence_action) {
            swal({
              title: res.api_area.message,
              text: res.api_area.correspondence_action,
              buttonsStyling: false,
              confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
              cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
              type: 'warning',
              showCancelButton: true,
              reverseButtons: true,
              confirmButtonText: res.common_area.label.VueButton_change_work_day.split(',')[0],
              cancelButtonText: res.common_area.label.VueButton_change_absent_day.split(',')[0],
              width: 650,
              allowOutsideClick: false
            }).then(() => {
              this.apply(operation, false)
            }).catch(() => {
              this.apply(operation, true)
            })
          } else {
            this.afterSuccess(res)
          }
        }).catch(() => {
          this.isChanging = false
        })
      },
      submitApplication () {
        if (this.isDeleteStampPopup && this.applicationFormCode === '0000000003') {
          let isNonStamp = false
          for (const row of this.groundList) {
            console.log(row, this.application.ground_code)
            if (row.value === this.application.ground_code) {
              isNonStamp = row.non_stamp_flg === 2
              break
            }
          }
          if (isNonStamp) {
            swal({
              title: this.deleteStampMessage,
              text: this.deleteStampMessageAction,
              buttonsStyling: false,
              confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
              cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
              type: 'warning',
              showCancelButton: true,
              reverseButtons: true,
              confirmButtonText: this.$t('button.yes'),
              cancelButtonText: this.$t('button.no'),
              width: 550,
              allowOutsideClick: false
            }).then(() => {
              this.execSubmitApplication('apply')
            })
            return
          }
        }
        this.execSubmitApplication('apply')
      },
      saveAsDraft () {
        this.execSubmitApplication('draft')
      },
      cancelApplication () {
        this.editApplicationInfoDto()
        this.apply('cancel')
      },
      pullbackApplication () {
        this.editApplicationInfoDto()
        this.apply('pullback')
      },
      afterSuccess (res) {
        if (this.isModal) {
          this.isChanged = true
          switch (this.applicationFormCode) {
            case '0000000016':
              if (!this.approveRecord) {
                if (res.api_area && res.api_area.length > 0) {
                  this.errMsgs4.push(this.$t('message.invalid_attendance_record'))
                  this.attendanceRecordErrors = res.api_area
                }
              }
              break
          }
        } else {
          switch (this.applicationFormCode) {
            case '0000000001':
              this.application.target_date = undefined
              this.application.work_schedule_code = undefined
              this.application.actual_start_end_time = undefined
              this.application.start_hours = ''
              this.application.start_minutes = ''
              this.application.end_hours = ''
              this.application.end_minutes = ''
              this.application.business_content = undefined
              this.application.supplement = undefined
              break
            case '0000000002':
              this.application.target_date = undefined
              this.application.work_schedule_code = undefined
              this.application.actual_overtime_minutes = undefined
              this.application.overtime_hours = '0'
              this.application.overtime_minutes = '00'
              this.application.business_content = undefined
              this.application.supplement = undefined
              break
            case '0000000003':
              this.application.target_date = undefined
              // this.application.work_schedule_code = undefined
              this.application.actual_ground = undefined
              this.application.ground_code = undefined
              this.application.supplement = undefined
              break
            case '0000000004':
              this.application.target_date = undefined
              this.application.work_schedule_code = undefined
              this.application.actual_overwork_minutes = undefined
              this.application.late_night_overwork_minutes = undefined
              this.application.business_content = undefined
              this.application.supplement = undefined
              break
            case '0000000005':
            case '0000000006':
              this.application.target_date = undefined
              this.application.work_schedule_code = undefined
              this.application.actual_late_early_minutes = undefined
              this.application.late_early_departure_minutes = undefined
              this.application.business_content = undefined
              this.application.supplement = undefined
              break
            case '0000000007':
              this.application.target_date = undefined
              this.application.work_schedule_code = undefined
              this.application.actual_holiday_minutes = undefined
              this.application.holiday_minutes = undefined
              this.application.business_content = undefined
              this.application.supplement = undefined
              break
            case '0000000008':
              this.application.parental_leave_type.value = 1
              this.application.term_from = undefined
              this.application.term_to = undefined
              this.application.term_time_from_hour = '0'
              this.application.term_time_from_minute = '00'
              this.application.term_time_to_hour = '0'
              this.application.term_time_to_minute = '00'
              this.application.business_content = undefined
              break
            case '0000000009':
            case '0000000010':
              this.application.paid_holiday_type.value = '1'
              this.application.term_from = undefined
              this.application.term_to = undefined
              this.application.term_time_from_hour = '0'
              this.application.term_time_from_minute = '00'
              this.application.term_time_to_hour = '0'
              this.application.term_time_to_minute = '00'
              this.application.business_content = undefined
              break
            case '0000000011':
            case '0000000012':
              // this.application.work_schedule_code = undefined
              this.application.transfer_work_date = undefined
              this.application.transfer_holiday_date = undefined
              this.application.business_content = undefined
              this.application.supplement = undefined
              break
            case '0000000013':
              this.application.need_to_exceed = undefined
              this.application.business_content = undefined
              this.application.supplement = undefined
              break
            case '0000000016':
              if (res.api_area && res.api_area.length > 0) {
                this.errMsgs4.push(this.$t('message.invalid_attendance_record'))
                this.attendanceRecordErrors = res.api_area
              }
              break
            case '0000000018':
              this.application.after_post_code = undefined
              this.application.after_prefectures = undefined
              this.application.after_city = undefined
              this.application.after_town = undefined
              this.application.after_building = undefined
              this.application.after_tel = undefined
              this.application.after_emergency_contact = undefined
              this.application.after_other = undefined
              break
            case '0000000019':
              for (const row of this.application.after) {
                row.payment_unit = undefined
                row.target_date = undefined
                row.traffic_division = undefined
                row.transportation_name = undefined
                row.start_section = undefined
                row.end_section = undefined
                row.distance_to_use_transportation_equipment = undefined
                row.payment_amount = undefined
              }
              break
            case '0000000020':
              this.application.after_employee_name = undefined
              this.application.after_mail_address = undefined
              this.application.after_sex = undefined
              this.application.after_feliCa = undefined
              this.application.after_my_number = undefined
              this.application.supplement = undefined
              break
          }
        }
        this.isChanging = false
        this.$refs.frame.parentNode.parentNode.parentNode.scrollTop = 0
        this.componentKey += 1
        if (!this.isModal) {
          this.initApply()
        }
      },
      approve (operation) {
        this.$refs.observer.validate().then(isValid => {
          if (!isValid) {
            this.$emit('on-submit', this.registerForm, isValid)
            return
          }
          this.isChanging = true
          const params = {
            screen_code: this.screenCode,
            company_code: this.$store.state.loginUser.companyCode,
            group_code: this.$store.state.loginUser.groupCode,
            employee_code: this.$store.state.loginUser.employeeCode,
            api_area: {
              application_number: this.applicationInfoDto.application_number,
              target_company_code: this.applicationInfoDto.target_company_code,
              route_type: parseInt(this.applicationInfoDto.route_type, 10),
              route_number: this.applicationInfoDto.route_number,
              approverl_company_code: this.approveRecord.approverl_company_code,
              approverl_group_code: this.approveRecord.approverl_group_code ? this.approveRecord.approverl_group_code : this.$store.state.loginUser.groupCode,
              approverl_employee_code: this.approveRecord.approverl_employee_code,
              approverl_comment: this.emptyToBlank(this.approveInfoDto.comment),
              screen_mode: parseInt(this.applicationInfoDto.screen_mode, 10),
              append_title: this.emptyToBlank(this.approveInfoDto.append_title),
              append_path: this.approveInfoDto.append_path
            }
          }
          this.send(this.getApproveUrl(operation), params)
          .then(res => {
            if (window.currentUrl === '/01/menu01Screen010/search' || window.currentUrl === '/09/menu09Screen012/search') {
              this.isChanged = true
              this.doClose()
            }
            this.afterSuccess()
          }).catch(() => {
            this.isChanging = false
          })
        })
      },
      approveApplication () {
        if (this.applicationFormCode === '0000000002' && this.application.limit_over_message) {
          swal({
            text: this.application.limit_over_message,
            buttonsStyling: false,
            confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
            cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
            type: 'warning',
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: this.$t('button.yes'),
            cancelButtonText: this.$t('button.no'),
            width: 550
          }).then(() => {
            this.approve('approve')
          })
        } else {
          this.approve('approve')
        }
      },
      rejectApplication () {
        if (this.approveInfoDto.comment) {
          this.approveInfoDto.comment_required = ''
          this.approve('denial')
        } else {
          swal({
            html: this.$t('message.field_required').replace('{_field_}', this.$t('label.comment')),
            type: 'error',
            confirmButtonClass: 'btn btn-warning btn-sm btn-default',
            buttonsStyling: false,
            allowOutsideClick: false
          })
        }
      },
      remandApplication () {
        this.approve('drawal')
      },
      pendingApplication () {
        this.approve('hold')
      },
      preapproveApplication () {
        this.approve('anticipatingapprove')
      },
      pullupApplication () {
        this.approve('hikeapprove')
      },
      confirmApplication () {
        this.approve('confirmation')
      },
      forceApprove () {
        if (this.approveInfoDto.comment) {
          this.approveInfoDto.comment_required = ''
          this.approve('forcedapprove')
        } else {
          swal({
            html: this.$t('message.field_required').replace('{_field_}', this.$t('label.comment')),
            type: 'error',
            confirmButtonClass: 'btn btn-warning btn-sm btn-default',
            buttonsStyling: false,
            allowOutsideClick: false
          })
        }
      },
      correctApplication () {
        this.applicationInfoDto.re_application_number = this.applicationInfoDto.application_number
        this.applicationInfoDto.application_number = undefined
        this.applicationInfoDto.apply_date = this.formatToday()
        this.reapplicationRecord = {
          screen_mode: 7,
          company_code: this.applyRecord.company_code,
          group_code: this.applyRecord.group_code,
          employee_code: this.applyRecord.employee_code,
          application_number: this.applyRecord.application_number,
          route_type: this.applyRecord.route_type,
          route_number: this.applyRecord.route_number
        }
        for (let i = this.applicationInfoDto.approverl_list.length - 1; i >= 0; i--) {
          if (this.applicationInfoDto.approverl_list[i].route_type === null) {
            this.applicationInfoDto.approverl_list.splice(i, 1)
          }
        }
        for (let i = this.individualRoute.length - 1; i >= 0; i--) {
          if (this.individualRoute[i].route_order === null) {
            this.individualRoute.splice(i, 1)
          }
        }
        for (let i = this.commonRoute.length - 1; i >= 0; i--) {
          if (this.commonRoute[i].route_order === null) {
            this.commonRoute.splice(i, 1)
          }
        }
        this.applicationInfoDto.route_history_list = []
        this.historyRoute = []
        this.applicationInfoDto.screen_mode = '1'
        this.$refs.frame.parentNode.parentNode.parentNode.scrollTop = 0
        this.componentKey += 1
        this.applyRecord = undefined
        this.initApply(this.application.target_date, this.application.work_schedule_code)
      },
      doClose () {
        this.$emit('close', this.isChanged, this.attendanceRecordErrors)
      },
      setRoute (res) {
        if (!res.api_area.approverlDto && !res.api_area.applicationInfoDto) {
          this.individualRoute = []
          this.commonRoute = []
          this.historyRoute = []
          return
        }
        this.labels.is_individual_route = res.api_area.applicationInfoDto ? res.api_area.applicationInfoDto.is_individual_route : false
        this.individualRoute = []
        this.commonRoute = []
        this.historyRoute = []
        const attachedFiles = []
        const approveInfoDto = {}
        const approverlList = res.api_area.approverlDto ? res.api_area.approverlDto.approverl_data_info_list : res.api_area.applicationInfoDto.approverl_list
        let routeHistoryList = res.api_area.approverlDto ? res.api_area.approverlDto.route_history_list : res.api_area.applicationInfoDto.route_history_list
        if (routeHistoryList) {
          for (const routeHistory of routeHistoryList) {
            routeHistory.route_type = 3
          }
        } else {
          routeHistoryList = []
        }
        const screenMode = res.api_area.approverlDto ? res.api_area.approverlDto.screen_mode : res.api_area.applicationInfoDto.screen_mode
        for (const routeList of [approverlList, routeHistoryList]) {
          for (let route of routeList) {
            let approvalFunctionName = ''
            for (const approvalFunction of res.common_area.combo_data.approval_function_list) {
              if (approvalFunction.value === route.approval_function) {
                approvalFunctionName = this.getLabel(approvalFunction.label)
                break
              }
            }
            if (route.activity_status === 9) {
              for (const approvalFunction of res.common_area.combo_data.approval_function_list) {
                if (approvalFunction.value === 2) {
                  route.approval_function = approvalFunction.value
                  approvalFunctionName = this.getLabel(approvalFunction.label)
                  break
                }
              }
            }
            let data = {
              isFixed: true,
              status_label: this.getLabel(route.workflow_status_label),
              route_order: route.route_number,
              company_code: route.workflow_company_code ? route.workflow_company_code : route.approverl_company_code,
              company_name: route.workflow_company_name ? route.workflow_company_name : route.approverl_company_name,
              groups: [{group_code: route.approverl_group_code, group_name: route.approverl_group_name}],
              group_code: route.workflow_group_code ? route.workflow_group_code : route.approverl_group_code,
              group_name: route.workflow_group_name ? route.workflow_group_name : route.approverl_group_name,
              applicant_employee_code: route.deputy_approverl_employee_code || route.approverl_role_code || route.approverl_employee_code ? '' : route.applicant_employee_code,
              applicant_employee_name: route.deputy_approverl_employee_code || route.approverl_role_code || route.approverl_employee_code ? '' : route.applicant_employee_name,
              target_employee_code: route.deputy_approverl_employee_code || route.approverl_role_code || route.approverl_employee_code ? '' : route.target_employee_code,
              target_employee_name: route.deputy_approverl_employee_code || route.approverl_role_code || route.approverl_employee_code ? '' : route.target_employee_name,
              apply_date: route.deputy_approverl_employee_code || route.approverl_role_code || route.approverl_employee_code ? '' : route.apply_date,
              employee_code: route.deputy_approverl_employee_code ? route.deputy_approverl_employee_code : route.approverl_role_code ? '' : route.approverl_employee_code ? route.approverl_employee_code : '',
              employee_name: route.deputy_approverl_employee_code ? route.deputy_approverl_employee_name : route.approverl_role_code ? route.approverl_role_name : route.approverl_employee_code ? route.approverl_employee_name : '',
              arrival_date: route.reaching_date,
              process_date: route.process_date,
              auth_code: String(route.approval_function),
              auth_name: approvalFunctionName,
              label_comment_exist: route.approverl_comment ? this.$t('label.available') : '',
              comment: route.approverl_comment
            }
            if (route.route_type === null || route.route_type === 1) {
              this.individualRoute.push(data)
            } else if (route.route_type === 2) {
              this.commonRoute.push(data)
            } else {
              this.historyRoute.push(data)
            }
            // 添付ファイル
            if (route.appended_data && route.appended_data.append_path !== null && route.appended_data.append_path !== '') {
              let isExist = false
              for (const row of attachedFiles) {
                if (row.route_type === route.route_type && row.route_number === route.route_number) {
                  isExist = true
                  break
                }
              }
              if (!isExist) {
                if (screenMode === 4 && !!this.approveRecord && this.approveRecord.approverl_employee_code === route.approverl_employee_code) {
                  route.appended_data.is_fixed = false
                  approveInfoDto.append_title = route.appended_data.append_title
                  approveInfoDto.append_path = null
                } else {
                  route.appended_data.is_fixed = true
                }
                route.appended_data.employee_code = route.approverl_employee_code
                route.appended_data.employee_name = route.approverl_employee_name
                route.appended_data.route_type = route.route_type
                route.appended_data.route_number = route.route_number
                attachedFiles.push(route.appended_data)
              }
            }
          }
        }
        approveInfoDto.attached_files = attachedFiles
        approveInfoDto.comment = ''
        approveInfoDto.comment_required = ''
        this.approveInfoDto = approveInfoDto
      },
      afterInit1 (res) {
        this.activityNames = res.common_area.combo_data.activity_status_list
        this.labels = this.getLabels(res.common_area)
        this.roles = res.common_area.button_role
        this.setRoute(res)
        for (const applicationStatus of res.common_area.combo_data.application_status_list) {
          if (res.api_area.approverlDto) {
            if (applicationStatus.value === res.api_area.approverlDto.application_status) {
              this.labels.application_status = this.getLabel(applicationStatus.label)
            }
          } else if (res.api_area.applicationInfoDto) {
            if (applicationStatus.value === res.api_area.applicationInfoDto.application_status) {
              this.labels.application_status = this.getLabel(applicationStatus.label)
            }
          }
        }
        this.comboData = this.getComboData(this.comboData, res.common_area.combo_data)
        this.comboData.direct_approval_function_list = []
        this.comboData.indirect_approval_function_list = []
        for (const approvalFunction of this.comboData.approval_function_list) {
          if (approvalFunction.value === '1') {
            this.comboData.direct_approval_function_list.push(approvalFunction)
          }
          if (approvalFunction.value !== '2') {
            this.comboData.indirect_approval_function_list.push(approvalFunction)
          }
        }
        if (res.api_area.approverlDto) {
          res.api_area.approverlDto.screen_mode = String(res.api_area.approverlDto.screen_mode)
          this.applicationInfoDto = res.api_area.approverlDto
        } else if (res.api_area.applicationInfoDto) {
          if (res.api_area.applicationInfoDto.screen_mode === 8) {
            if (this.attendanceRecord) {
              res.api_area.applicationInfoDto.screen_mode = String(this.attendanceRecord.screen_mode)
            } else if (this.approveRecord) {
              res.api_area.applicationInfoDto.screen_mode = String(this.approveRecord.screen_mode)
            } else if (this.applyRecord) {
              res.api_area.applicationInfoDto.screen_mode = String(this.applyRecord.screen_mode)
            }
          } else {
            res.api_area.applicationInfoDto.screen_mode = String(res.api_area.applicationInfoDto.screen_mode)
          }
          this.applicationInfoDto = res.api_area.applicationInfoDto
          this.hasBoss = res.api_area.boss_field
          if (!res.api_area.applicationInfoDto.application_number) {
            this.applicationInfoDto.apply_date = this.formatNow()
          }
        } else {
          this.applicationInfoDto = {
            screen_mode: '1'
          }
        }
        console.log('screen_mode: ' + this.applicationInfoDto.screen_mode)
      },
      afterInit2 (res) {
        switch (this.applicationFormCode) {
          case '0000000001':
            if (this.initData && this.applicationInfoDto.application_number === 0) {
              res.api_area.imprintCorrectionApplication.imprint_correction_type = String(this.initData.stamp_type)
              res.api_area.imprintCorrectionApplication.target_date = this.initData.target_date
              res.api_area.imprintCorrectionApplication.work_schedule_code = this.initData.work_schedule_code_bk
              res.api_area.imprintCorrectionApplication.telework_flg = !!this.initData.telework_flg
              res.api_area.imprintCorrectionApplication.start_hours = ''
              res.api_area.imprintCorrectionApplication.start_minutes = ''
              res.api_area.imprintCorrectionApplication.end_hours = ''
              res.api_area.imprintCorrectionApplication.end_minutes = ''
              res.api_area.imprintCorrectionApplication.before_start_hours = this.initData.stamping_start_time ? this.initData.stamping_start_time.split(':')[0] : ''
              res.api_area.imprintCorrectionApplication.before_start_minutes = this.initData.stamping_start_time ? this.initData.stamping_start_time.split(':')[1] : ''
              res.api_area.imprintCorrectionApplication.before_end_hours = this.initData.stamping_end_time ? this.initData.stamping_end_time.split(':')[0] : ''
              res.api_area.imprintCorrectionApplication.before_end_minutes = this.initData.stamping_end_time ? this.initData.stamping_end_time.split(':')[1] : ''
              res.api_area.imprintCorrectionApplication.break_time_list = []
              this.addDeletedWorkSchedule()
            } else {
              if (!res.api_area.imprintCorrectionApplication.work_schedule_code && res.api_area.work_schedule_list && res.api_area.work_schedule_list.length > 0) {
                res.api_area.imprintCorrectionApplication.work_schedule_code = res.api_area.work_schedule_list[0]
              }
              res.api_area.imprintCorrectionApplication.telework_flg = !!res.api_area.imprintCorrectionApplication.telework_flg
              if (res.api_area.imprintCorrectionApplication.stamping_start_time) {
                res.api_area.imprintCorrectionApplication.start_hours = String(parseInt(res.api_area.imprintCorrectionApplication.stamping_start_time.split(':')[0], 10))
                res.api_area.imprintCorrectionApplication.start_minutes = res.api_area.imprintCorrectionApplication.stamping_start_time.split(':')[1]
              } else {
                res.api_area.imprintCorrectionApplication.start_hours = ''
                res.api_area.imprintCorrectionApplication.start_minutes = ''
              }
              if (res.api_area.imprintCorrectionApplication.stamping_end_time) {
                const endHours = parseInt(res.api_area.imprintCorrectionApplication.stamping_end_time.split(':')[0], 10)
                if (endHours < 24) {
                  res.api_area.imprintCorrectionApplication.end_hours = String(endHours)
                  res.api_area.imprintCorrectionApplication.next_day = false
                } else {
                  res.api_area.imprintCorrectionApplication.end_hours = String(endHours - 24)
                  res.api_area.imprintCorrectionApplication.next_day = true
                }
                res.api_area.imprintCorrectionApplication.end_minutes = res.api_area.imprintCorrectionApplication.stamping_end_time.split(':')[1]
              } else {
                res.api_area.imprintCorrectionApplication.end_hours = ''
                res.api_area.imprintCorrectionApplication.end_minutes = ''
              }
              if (res.api_area.imprintCorrectionApplication.before_start_time) {
                res.api_area.imprintCorrectionApplication.before_start_hours = String(parseInt(res.api_area.imprintCorrectionApplication.before_start_time.split(':')[0], 10))
                res.api_area.imprintCorrectionApplication.before_start_minutes = res.api_area.imprintCorrectionApplication.before_start_time.split(':')[1]
              } else {
                res.api_area.imprintCorrectionApplication.before_start_hours = ''
                res.api_area.imprintCorrectionApplication.before_start_minutes = ''
              }
              if (res.api_area.imprintCorrectionApplication.before_end_time) {
                res.api_area.imprintCorrectionApplication.before_end_hours = String(parseInt(res.api_area.imprintCorrectionApplication.before_end_time.split(':')[0], 10))
                res.api_area.imprintCorrectionApplication.before_end_minutes = res.api_area.imprintCorrectionApplication.before_end_time.split(':')[1]
              } else {
                res.api_area.imprintCorrectionApplication.before_end_hours = ''
                res.api_area.imprintCorrectionApplication.before_end_minutes = ''
              }
              res.api_area.imprintCorrectionApplication.breakTimeListErrors = []
              res.api_area.imprintCorrectionApplication.business_content = res.api_area.imprintCorrectionApplication.imprint_correction_content
              res.api_area.imprintCorrectionApplication.stamping_start_time_change_flg = !!res.api_area.imprintCorrectionApplication.stamping_start_time_change_flg
              res.api_area.imprintCorrectionApplication.stamping_end_time_change_flg = !!res.api_area.imprintCorrectionApplication.stamping_end_time_change_flg
              res.api_area.imprintCorrectionApplication.telework_change_flg = !!res.api_area.imprintCorrectionApplication.telework_change_flg
              if (this.$route.params.target_date) {
                res.api_area.imprintCorrectionApplication.stamping_start_time_change_flg = this.$route.params.stamping_start_time_change_flg
                res.api_area.imprintCorrectionApplication.stamping_end_time_change_flg = this.$route.params.stamping_end_time_change_flg
                res.api_area.imprintCorrectionApplication.telework_change_flg = this.$route.params.telework_change_flg
              }
            }
            res.api_area.imprintCorrectionApplication.break_time_list = []
            for (const row of res.api_area.imprintCorrectionApplicationDetail.break_time_list) {
              row.start_time = this.convertHhmmss2Hhmm(row.start_time)
              row.end_time = this.convertHhmmss2Hhmm(row.end_time)
              row.re_start_time = this.convertHhmmss2Hhmm(row.re_start_time)
              row.re_end_time = this.convertHhmmss2Hhmm(row.re_end_time)
              res.api_area.imprintCorrectionApplication.break_time_list.push(row)
            }
            res.api_area.imprintCorrectionApplication.org_telework_flg = res.api_area.imprintCorrectionApplication.telework_flg
            res.api_area.imprintCorrectionApplication.org_start_hours = res.api_area.imprintCorrectionApplication.before_start_hours
            res.api_area.imprintCorrectionApplication.org_start_minutes = res.api_area.imprintCorrectionApplication.before_start_minutes
            res.api_area.imprintCorrectionApplication.org_end_hours = res.api_area.imprintCorrectionApplication.before_end_hours
            res.api_area.imprintCorrectionApplication.org_end_minutes = res.api_area.imprintCorrectionApplication.before_end_minutes
            const workScheduleList = []
            if (res.api_area.work_schedule_list) {
              for (const row of res.api_area.work_schedule_list) {
                for (const row2 of res.common_area.combo_data.work_schedule_list) {
                  if (row === row2.value) {
                    workScheduleList.push(row2)
                    break
                  }
                }
              }
            } else {
              for (const row of res.common_area.combo_data.work_schedule_list) {
                workScheduleList.push(row)
              }
            }
            res.api_area.imprintCorrectionApplication.work_schedule_list = workScheduleList
            this.application = res.api_area.imprintCorrectionApplication
            break
          case '0000000002':
            this.comboData.reasons_over_work_contents_list = ['']
            if (res.api_area.overtimeApplication.reasons_over_work_contents_style1) {
              this.comboData.reasons_over_work_contents_list.push(res.api_area.overtimeApplication.reasons_over_work_contents_style1)
            }
            if (res.api_area.overtimeApplication.reasons_over_work_contents_style2) {
              this.comboData.reasons_over_work_contents_list.push(res.api_area.overtimeApplication.reasons_over_work_contents_style2)
            }
            if (this.initData && this.applicationInfoDto.application_number === 0) {
              res.api_area.overtimeApplication.target_date = this.initData.target_date
              const overtimeMinutes = this.initData.overwork_minutes ? parseInt(this.initData.overwork_minutes, 10) : this.initData.job_overwork_minutes + this.initData.legal_overwork_minutes
              res.api_area.overtimeApplication.overtime_hours = String(Math.floor(overtimeMinutes / 60))
              res.api_area.overtimeApplication.overtime_minutes = ('0' + String(overtimeMinutes % 60)).slice(-2)
              res.api_area.overtimeApplication.work_schedule_code = this.initData.work_schedule_code_bk
              res.api_area.overtimeApplication.is_fixed = true
            } else {
              res.api_area.overtimeApplication.overtime_hours = String(Math.floor(res.api_area.overtimeApplication.overwork_minutes / 60))
              res.api_area.overtimeApplication.overtime_minutes = ('0' + String(res.api_area.overtimeApplication.overwork_minutes % 60)).slice(-2)
              if (!res.api_area.overtimeApplication.work_schedule_code && res.common_area.combo_data.work_schedule_list.length > 0) {
                res.api_area.overtimeApplication.work_schedule_code = res.common_area.combo_data.work_schedule_list[0].value
              }
              if (res.api_area.overtimeApplication.reasons_over_work_contents === res.api_area.overtimeApplication.reasons_over_work_contents_style1) {
                res.api_area.overtimeApplication.limit_legal_one_month_minutes = res.api_area.overtimeApplication.limit_legal_one_month_minutes_style1
              } else if (res.api_area.overtimeApplication.reasons_over_work_contents === res.api_area.overtimeApplication.reasons_over_work_contents_style2) {
                res.api_area.overtimeApplication.limit_legal_one_month_minutes = res.api_area.overtimeApplication.limit_legal_one_month_minutes_style2
              } else {
                res.api_area.overtimeApplication.limit_legal_one_month_minutes = 0
              }
              res.api_area.overtimeApplication.remaining_monthly_overwork_minutes = res.api_area.overtimeApplication.limit_legal_one_month_minutes - res.api_area.overtimeApplication.monthly_overwork_minutes
              res.api_area.overtimeApplication.is_fixed = false
            }
            this.addDeletedWorkSchedule()
            res.api_area.overtimeApplication.remaining_monthly_overwork_minutes = res.api_area.overtimeApplication.limit_legal_one_month_minutes - res.api_area.overtimeApplication.monthly_overwork_minutes
            this.application = res.api_area.overtimeApplication
            break
          case '0000000003':
            let isExist = false
            for (const row of res.common_area.combo_data.workflow_ground_code_list) {
              if (row.value === res.api_area.groundConfirmEmployee.ground_data.value) {
                isExist = true
                break
              }
            }
            if (!isExist) {
              const groundList = [...res.common_area.combo_data.workflow_ground_code_list]
              groundList.push({
                label: res.api_area.groundConfirmEmployee.ground_data.label,
                value: res.api_area.groundConfirmEmployee.ground_data.value
              })
              this.comboData.workflow_ground_code_list = groundList
            }
            this.isDeleteStampPopup = res.api_area.is_delete_stamp_popup
            this.deleteStampMessage = !res.api_area.delete_stamp_message ? '該当日は打刻されています。' : res.api_area.delete_stamp_message
            this.deleteStampMessageAction = !res.api_area.delete_stamp_message_action ? '打刻を削除し、事由を変更しても宜しいでしょうか？' : res.api_area.delete_stamp_message_action
            this.groundList = res.common_area.combo_data.ground_list
            if (this.initData && this.applicationInfoDto.application_number === 0) {
              res.api_area.groundConfirmEmployee.target_date = this.initData.target_date
              res.api_area.groundConfirmEmployee.actual_ground = this.initData.ground_name
              res.api_area.groundConfirmEmployee.ground_code = this.initData.ground_code
              // res.api_area.groundConfirmEmployee.work_schedule_code = this.initData.work_schedule_code_bk
              res.api_area.groundConfirmEmployee.is_fixed = true
            } else {
              res.api_area.groundConfirmEmployee.target_date = res.api_area.groundConfirmEmployee.confirm_day
              if (res.api_area.groundConfirmEmployee.ground_data) {
                res.api_area.groundConfirmEmployee.ground_code = res.api_area.groundConfirmEmployee.ground_data.value
              }
              if (res.api_area.groundConfirmEmployee.re_ground_data) {
                res.api_area.groundConfirmEmployee.actual_ground = res.api_area.groundConfirmEmployee.re_ground_data.label
              }
              res.api_area.groundConfirmEmployee.supplement = res.api_area.groundConfirmEmployee.contents
              // if (!res.api_area.groundConfirmEmployee.work_schedule_code && res.common_area.combo_data.work_schedule_list.length > 0) {
              //   res.api_area.groundConfirmEmployee.work_schedule_code = res.common_area.combo_data.work_schedule_list[0].value
              // }
              res.api_area.groundConfirmEmployee.is_fixed = false
            }
            if (this.initData && this.initData.new_ground_code) {
              res.api_area.groundConfirmEmployee.ground_code = this.initData.new_ground_code
            }
            this.addDeletedWorkSchedule()
            this.application = res.api_area.groundConfirmEmployee
            break
          case '0000000004':
            if (this.initData && this.applicationInfoDto.application_number === 0) {
              res.api_area.lateNightOverworkApplication.target_date = this.initData.target_date
              res.api_area.lateNightOverworkApplication.late_night_overwork_minutes = String(this.initData.late_night_overwork_minutes)
              res.api_area.lateNightOverworkApplication.work_schedule_code = this.initData.work_schedule_code_bk
              res.api_area.lateNightOverworkApplication.is_fixed = true
            } else {
              if (res.api_area.lateNightOverworkApplication.late_night_overwork_minutes) {
                res.api_area.lateNightOverworkApplication.late_night_overwork_minutes = String(res.api_area.lateNightOverworkApplication.late_night_overwork_minutes)
              }
              if (!res.api_area.lateNightOverworkApplication.work_schedule_code && res.common_area.combo_data.work_schedule_list.length > 0) {
                res.api_area.lateNightOverworkApplication.work_schedule_code = res.common_area.combo_data.work_schedule_list[0].value
              }
              res.api_area.lateNightOverworkApplication.is_fixed = false
            }
            this.addDeletedWorkSchedule()
            this.application = res.api_area.lateNightOverworkApplication
            break
          case '0000000005':
          case '0000000006':
            if (res.api_area.earlyDepartureTimeApplication) {
              res.api_area.lateTimeApplication = res.api_area.earlyDepartureTimeApplication
              res.api_area.lateTimeApplication.late_minutes = res.api_area.lateTimeApplication.early_departure_minutes
              res.api_area.lateTimeApplication.late_content = res.api_area.lateTimeApplication.early_departure_content
            }
            const lateEarlyDepartureTypeList = [
              {
                id: '1',
                label: this.labels.lateTimeApplication,
                value: '1'
              },
              {
                id: '2',
                label: this.labels.earlyDepartureTimeApplication,
                value: '2'
              }
            ]
            let canSelect = true
            if (res.api_area.application_control) {
              if (res.api_area.application_control.is_early_departure_time_application) {
                if (!res.api_area.application_control.is_late_time_application) {
                  this.selectedApplicationFormCode = '0000000006'
                  canSelect = false
                }
              } else {
                canSelect = false
                if (res.api_area.application_control.is_late_time_application) {
                  this.selectedApplicationFormCode = '0000000005'
                }
              }
            }
            res.api_area.lateTimeApplication.late_early_departure_type = {
              value: this.selectedApplicationFormCode === '0000000005' ? '1' : '2',
              disabled: !canSelect,
              items: lateEarlyDepartureTypeList
            }
            if (this.initData && this.applicationInfoDto.application_number === 0) {
              res.api_area.lateTimeApplication.target_date = this.initData.target_date
              const lateEarlyDepartureMinutes = this.selectedApplicationFormCode === '0000000005' ? this.initData.late_minutes : this.initData.early_departure_minutes
              res.api_area.lateTimeApplication.late_early_departure_minutes = String(lateEarlyDepartureMinutes)
              res.api_area.lateTimeApplication.work_schedule_code = this.initData.work_schedule_code_bk
              res.api_area.lateTimeApplication.is_fixed = true
            } else {
              if (res.api_area.lateTimeApplication.late_minutes) {
                res.api_area.lateTimeApplication.late_early_departure_minutes = String(res.api_area.lateTimeApplication.late_minutes)
              }
              res.api_area.lateTimeApplication.business_content = res.api_area.lateTimeApplication.late_content
              if (!res.api_area.lateTimeApplication.work_schedule_code && res.common_area.combo_data.work_schedule_list.length > 0) {
                res.api_area.lateTimeApplication.work_schedule_code = res.common_area.combo_data.work_schedule_list[0].value
              }
              res.api_area.lateTimeApplication.is_fixed = false
            }
            this.addDeletedWorkSchedule()
            this.application = res.api_area.lateTimeApplication
            break
          case '0000000007':
            if (this.initData && this.applicationInfoDto.application_number === 0) {
              res.api_area.holidayWorkApplication.target_date = this.initData.target_date
              const holidayMinutes = this.initData.job_holiday_hours === 0 ? this.attendanceRecord.legal_holiday_overwork_minutes : this.attendanceRecord.job_holiday_hours
              res.api_area.holidayWorkApplication.holiday_minutes = String(holidayMinutes)
              res.api_area.holidayWorkApplication.work_schedule_code = this.initData.work_schedule_code_bk
              res.api_area.holidayWorkApplication.is_fixed = true
            } else {
              if (res.api_area.holidayWorkApplication.holiday_work_hours) {
                res.api_area.holidayWorkApplication.holiday_minutes = String(res.api_area.holidayWorkApplication.holiday_work_hours)
              }
              res.api_area.holidayWorkApplication.business_content = res.api_area.holidayWorkApplication.business_content
              if (!res.api_area.holidayWorkApplication.work_schedule_code && res.common_area.combo_data.work_schedule_list.length > 0) {
                res.api_area.holidayWorkApplication.work_schedule_code = res.common_area.combo_data.work_schedule_list[0].value
              }
              res.api_area.holidayWorkApplication.is_fixed = false
            }
            this.addDeletedWorkSchedule()
            this.application = res.api_area.holidayWorkApplication
            break
          case '0000000008':
            const parentalLeaveTypeList = []
            for (const row of res.common_area.combo_data.parental_leave_type_list) {
              if (row.value !== 3) {
                parentalLeaveTypeList.push({
                  id: row.value,
                  label: this.getLabel(row.label),
                  value: row.value
                })
              }
            }
            res.api_area.parentalLeave.parental_leave_type = {
              value: res.api_area.parentalLeave.parental_leave_type ? res.api_area.parentalLeave.parental_leave_type : res.api_area.parentalLeave.paid_holiday_type ? res.api_area.parentalLeave.paid_holiday_type : 1,
              disabled: false,
              items: parentalLeaveTypeList
            }
            if (res.api_area.parentalLeave.parental_leave_type.value === 2) {
              res.api_area.parentalLeave.term_time_from_hour = !res.api_area.parentalLeave.term_time_from ? '' : String(parseInt(res.api_area.parentalLeave.term_time_from.split(':')[0], 10))
              res.api_area.parentalLeave.term_time_from_minute = !res.api_area.parentalLeave.term_time_from ? '' : res.api_area.parentalLeave.term_time_from.split(':')[1]
              res.api_area.parentalLeave.term_time_to_hour = !res.api_area.parentalLeave.term_time_to ? '' : String(parseInt(res.api_area.parentalLeave.term_time_to.split(':')[0], 10))
              res.api_area.parentalLeave.term_time_to_minute = !res.api_area.parentalLeave.term_time_to ? '' : res.api_area.parentalLeave.term_time_to.split(':')[1]
            }
            if (!res.api_area.parentalLeave.term_time_from_hour) {
              res.api_area.parentalLeave.term_time_from_hour = '0'
              res.api_area.parentalLeave.term_time_from_minute = '00'
              res.api_area.parentalLeave.term_time_to_hour = '0'
              res.api_area.parentalLeave.term_time_to_minute = '00'
            }
            res.api_area.parentalLeave.business_content = res.api_area.parentalLeave.contents
            this.application = res.api_area.parentalLeave
            if (!this.application.total_hold_days && !this.application.total_hold_times && res.message.message_classification === 2) {
              this.isChanging = true
            }
            break
          case '0000000009':
          case '0000000010':
            const paidHolidayTypeList = []
            for (const row of res.common_area.combo_data.paid_holiday_type_list) {
              if (row.value !== 3 && row.value !== 5) {
                paidHolidayTypeList.push({
                  id: row.value,
                  label: this.getLabel(row.label),
                  value: row.value
                })
              }
            }
            res.api_area.paidLeaveEmployee.paid_holiday_type = {
              value: res.api_area.paidLeaveEmployee.paid_holiday_type ? res.api_area.paidLeaveEmployee.paid_holiday_type : 1,
              disabled: false,
              items: paidHolidayTypeList
            }
            if (res.api_area.paidLeaveEmployee.paid_holiday_type.value === 2 || res.api_area.paidLeaveEmployee.paid_holiday_type.value === 4) {
              res.api_area.paidLeaveEmployee.term_time_from_hour = String(parseInt(res.api_area.paidLeaveEmployee.term_time_from.split(':')[0], 10))
              res.api_area.paidLeaveEmployee.term_time_from_minute = res.api_area.paidLeaveEmployee.term_time_from.split(':')[1]
              res.api_area.paidLeaveEmployee.term_time_to_hour = String(parseInt(res.api_area.paidLeaveEmployee.term_time_to.split(':')[0], 10))
              res.api_area.paidLeaveEmployee.term_time_to_minute = res.api_area.paidLeaveEmployee.term_time_to.split(':')[1]
            }
            if (!res.api_area.paidLeaveEmployee.term_time_from_hour) {
              res.api_area.paidLeaveEmployee.term_time_from_hour = '0'
              res.api_area.paidLeaveEmployee.term_time_from_minute = '00'
              res.api_area.paidLeaveEmployee.term_time_to_hour = '0'
              res.api_area.paidLeaveEmployee.term_time_to_minute = '00'
            }
            res.api_area.paidLeaveEmployee.business_content = res.api_area.paidLeaveEmployee.contents
            this.application = res.api_area.paidLeaveEmployee
            // if (!this.application.total_hold_days && !this.application.total_hold_times && res.message.message_classification === 2) {
            //   this.isChanged = true
            // }
            this.calcDays()
            break
          case '0000000011':
          case '0000000012':
            // if (!res.api_area.transferHolidayWorkApplication.work_schedule_code && res.common_area.combo_data.work_schedule_list.length > 0) {
            //   res.api_area.transferHolidayWorkApplication.work_schedule_code = res.common_area.combo_data.work_schedule_list[0].value
            // }
            this.application = res.api_area.transferHolidayWorkApplication
            break
          case '0000000013':
            let dispJobMonth = res.api_area.timeLimitExceededApplication.job_month.substring(0, 4)
            dispJobMonth += this.$t('label.year')
            dispJobMonth += String(parseInt(res.api_area.timeLimitExceededApplication.job_month.substring(4), 10))
            dispJobMonth += this.$t('label.month')
            res.api_area.timeLimitExceededApplication.disp_job_month = dispJobMonth
            this.application = res.api_area.timeLimitExceededApplication
            break
          case '0000000014':
          case '0000000015':
            res.api_area.leaveJobApplication.append_list = res.api_area.append_list
            this.application = res.api_area.leaveJobApplication
            break
          case '0000000016':
            res.api_area.attendanceRecordApplication.hasInitData = !!this.attendanceRecord
            const year = res.api_area.attendanceRecordApplication.job_month.substring(0, 4)
            const month = res.api_area.attendanceRecordApplication.job_month.substring(4)
            res.api_area.attendanceRecordApplication.year = year
            res.api_area.attendanceRecordApplication.month = String(parseInt(month, 10))
            res.api_area.attendanceRecordApplication.working_data = [res.api_area.working_data]
            res.api_area.attendanceRecordApplication.is_absent_popup = res.api_area.is_absent_popup
            this.labels.employee = this.labels.employee_ryaku
            this.labels.estimated_overtime_hours = this.labels.estimated_overtime_hours_ryaku
            this.labels.real_total_minutes = this.labels.real_total_minutes_ryaku
            this.labels.job_total_minutes = this.labels.job_total_minutes_ryaku
            this.labels.job_overwork_minutes = this.labels.job_overwork_minutes_ryaku
            this.labels.job_holiday_hours = this.labels.job_holiday_hours_ryaku
            this.labels.legal_job_minutes = this.labels.legal_job_minutes_ryaku
            this.labels.legal_overwork_minutes = this.labels.legal_overwork_minutes_ryaku
            this.labels.legal_holiday_overwork_minutes = this.labels.legal_holiday_overwork_minutes_ryaku
            this.labels.late_night_overwork_minutes = this.labels.late_night_overwork_minutes_ryaku
            this.labels.lack_minutes = this.labels.lack_minutes_ryaku
            this.labels.break_minutes = this.labels.break_minutes_ryaku
            this.labels.late_minutes = this.labels.late_minutes_ryaku
            this.labels.early_departure_minutes = this.labels.early_departure_minutes_ryaku
            this.labels.paid_holiday_days = this.labels.paid_holiday_days_ryaku
            this.labels.paid_holiday_hours = this.labels.paid_holiday_hours_ryaku
            this.labels.compensatory_holiday_days = this.labels.compensatory_holiday_days_ryaku
            this.labels.closed_days = this.labels.closed_days_ryaku
            this.application = res.api_area.attendanceRecordApplication
            break
          case '0000000018':
            const stateName = this.getLabel(this.getSelectName(res.api_area.addressChangeApplicationOld.state_code, res.common_area.combo_data.state_list))
            const oldCityCode = res.api_area.addressChangeApplicationOld.municipality_code && res.api_area.addressChangeApplicationOld.municipality_code.indexOf('_') > -1 ? res.api_area.addressChangeApplicationOld.municipality_code : res.api_area.addressChangeApplicationOld.state_code + '_' + res.api_area.addressChangeApplicationOld.municipality_code
            const municipalityName = this.getSelectName(oldCityCode, res.common_area.combo_data.state_municipality_mapping_list.municipality_list)
            const newCityCode = res.api_area.addressChangeApplication.municipality_code && res.api_area.addressChangeApplication.municipality_code.indexOf('_') > -1 ? res.api_area.addressChangeApplication.municipality_code : res.api_area.addressChangeApplication.state_code + '_' + res.api_area.addressChangeApplication.municipality_code
            const appendPathList = []
            if (res.api_area.addressChangeApplicationDucument) {
              for (const row of res.api_area.addressChangeApplicationDucument) {
                appendPathList.push(row.append_path)
              }
            }
            res.api_area.addressChangeApplication = {
              before_post_code: res.api_area.addressChangeApplicationOld.post_code,
              before_prefectures: stateName,
              before_city: municipalityName,
              before_town: res.api_area.addressChangeApplicationOld.town,
              before_building: res.api_area.addressChangeApplicationOld.building,
              before_tel: res.api_area.addressChangeApplicationOld.tel,
              before_emergency_contact: res.api_area.addressChangeApplicationOld.emergency_contact,
              before_other: res.api_area.addressChangeApplicationOld.other,
              after_post_code: res.api_area.addressChangeApplication.post_code ? res.api_area.addressChangeApplication.post_code : res.api_area.addressChangeApplicationOld.post_code,
              after_prefectures: res.api_area.addressChangeApplication.post_code ? res.api_area.addressChangeApplication.state_code : res.api_area.addressChangeApplicationOld.state_code,
              after_city: res.api_area.addressChangeApplication.post_code ? newCityCode : oldCityCode,
              after_town: res.api_area.addressChangeApplication.post_code ? res.api_area.addressChangeApplication.town : res.api_area.addressChangeApplicationOld.town,
              after_building: res.api_area.addressChangeApplication.post_code ? res.api_area.addressChangeApplication.building : res.api_area.addressChangeApplicationOld.building,
              after_tel: res.api_area.addressChangeApplication.post_code ? res.api_area.addressChangeApplication.tel : res.api_area.addressChangeApplicationOld.tel,
              after_emergency_contact: res.api_area.addressChangeApplication.post_code ? res.api_area.addressChangeApplication.emergency_contact : res.api_area.addressChangeApplicationOld.emergency_contact,
              after_other: res.api_area.addressChangeApplication.post_code ? res.api_area.addressChangeApplication.other : res.api_area.addressChangeApplicationOld.other,
              append_path_list: appendPathList,
              supplement: res.api_area.addressChangeApplication.post_code ? res.api_area.addressChangeApplication.supplement : ''
            }
            this.comboData.prefectures_list = res.common_area.combo_data.state_municipality_mapping_list.state_list
            this.comboData.municipality_list = res.common_area.combo_data.state_municipality_mapping_list.municipality_list
            this.comboData.mapping_list = res.common_area.combo_data.state_municipality_mapping_list.state_municipality_mapping_list

            this.application = res.api_area.addressChangeApplication
            for (const row of this.comboData.prefectures_list) {
              if (row.value === this.application.before_prefectures) {
                this.application.before_prefectures = row.label
                break
              }
            }
            for (const row of this.comboData.municipality_list) {
              if (row.value === this.application.before_city) {
                this.application.before_city = row.label
                break
              }
            }
            break
          case '0000000019':
            const targetDateList4 = [{label: '', value: ''}]
            const targetDateList2 = [{label: '', value: ''}]
            const targetDateListAll = []
            for (const row of this.comboData.target_month_list) {
              if (row.value === '1' || row.value === '2' || row.value === '3') {
                targetDateList4.push(row)
              } else {
                targetDateList2.push(row)
              }
              targetDateListAll.push(row)
            }
            this.comboData.target_date_list_4 = targetDateList4
            this.comboData.target_date_list_2 = targetDateList2
            this.comboData.target_date_list_all = targetDateListAll
            this.labels.target_date = this.labels.target_month
            const before = []
            for (const row of res.api_area.commutingRouteChangeApplicationDetailOld) {
              let distanceToUseTransportationEquipmentLabel = ''
              for (const element of this.comboData.distance_to_use_transportation_equipment_list) {
                if (String(row.distance_to_use_transportation_equipment) === element.value) {
                  distanceToUseTransportationEquipmentLabel = element.label
                  break
                }
              }
              before.push({
                serial_number: row.serial_number,
                traffic_division: row.traffic_division ? String(row.traffic_division) : null,
                distance_to_use_transportation_equipment: row.distance_to_use_transportation_equipment ? String(row.distance_to_use_transportation_equipment) : null,
                distance_to_use_transportation_equipment_label: distanceToUseTransportationEquipmentLabel,
                payment_unit: row.payment_unit ? String(row.payment_unit) : null,
                target_date: row.target_month ? String(row.target_month) : null,
                payment_amount: row.payment_amount ? String(row.payment_amount) : null,
                transportation_name: row.transportation_name,
                start_section: row.start_section,
                end_section: row.end_section
              })
            }
            const after = []
            for (const row of res.api_area.commutingRouteChangeApplicationDetail) {
              let distanceToUseTransportationEquipmentLabel = ''
              for (const element of this.comboData.distance_to_use_transportation_equipment_list) {
                if (String(row.distance_to_use_transportation_equipment) === element.value) {
                  distanceToUseTransportationEquipmentLabel = element.label
                  break
                }
              }
              after.push({
                serial_number: row.serial_number,
                traffic_division: row.traffic_division ? String(row.traffic_division) : null,
                distance_to_use_transportation_equipment: row.distance_to_use_transportation_equipment ? String(row.distance_to_use_transportation_equipment) : null,
                distance_to_use_transportation_equipment_label: distanceToUseTransportationEquipmentLabel,
                payment_unit: row.payment_unit ? String(row.payment_unit) : null,
                target_date: row.target_month ? String(row.target_month) : null,
                payment_amount: row.payment_amount ? String(row.payment_amount) : null,
                transportation_name: row.transportation_name,
                start_section: row.start_section,
                end_section: row.end_section,
                target_date_list: row.payment_unit === 2 ? this.comboData.target_date_list_4 : row.payment_unit === 3 ? this.comboData.target_date_list_2 : []
              })
            }
            res.api_area.commutingRouteChangeApplication = {
              before: before,
              after: after,
              supplement: res.api_area.commutingRouteChangeApplication.supplement
            }

            this.comboData.distance_to_use_transportation_equipment_list.unshift({label: '', value: ''})
            this.comboData.distance_to_use_transportation_equipment_mapping = res.common_area.combo_data.distance_to_use_transportation_equipment_mapping_list.distance_to_use_transportation_equipment_mapping
            this.application = res.api_area.commutingRouteChangeApplication
            for (const row of this.application.before) {
              if (row.payment_amount) {
                row.payment_amount = this.formatNumber(row.payment_amount)
              }
            }
            break
          case '0000000020':
            const sexName = this.getLabel(this.getSelectName(res.api_area.personalInformationChangeApplicationOld.sex, res.common_area.combo_data.sex_list))
            res.api_area.personalInformationChangeApplication = {
              before_employee_name: res.api_area.personalInformationChangeApplicationOld.employee_name,
              before_mail_address: res.api_area.personalInformationChangeApplicationOld.mail_address,
              before_sex: res.api_area.personalInformationChangeApplicationOld.sex,
              before_sex_name: sexName,
              after_employee_name: res.api_area.personalInformationChangeApplication.employee_name ? res.api_area.personalInformationChangeApplication.employee_name : res.api_area.personalInformationChangeApplicationOld.employee_name,
              after_mail_address: res.api_area.personalInformationChangeApplication.employee_name ? res.api_area.personalInformationChangeApplication.mail_address : res.api_area.personalInformationChangeApplicationOld.mail_address,
              after_sex: res.api_area.personalInformationChangeApplication.employee_name ? String(res.api_area.personalInformationChangeApplication.sex) : String(res.api_area.personalInformationChangeApplicationOld.sex),
              supplement: res.api_area.personalInformationChangeApplication.employee_name ? res.api_area.personalInformationChangeApplication.supplement : ''
            }
            this.application = res.api_area.personalInformationChangeApplication
            break
        }
      },
      afterInit3 () {
        if (this.isModal && window.innerHeight - this.$refs.frame.clientHeight < 550) {
          this.contentStyle = 'overflow-y: auto; height: calc(100vh - 200px);'
        }
        this.componentKey += 1
      },
      addDeletedWorkSchedule () {
        if (!this.initData) return
        let isExist = false
        for (const row of this.comboData.work_schedule_list) {
          if (row.value === this.initData.work_schedule_code_bk) {
            isExist = true
            break
          }
        }
        if (!isExist) {
          this.comboData.work_schedule_list.push({
            label: this.initData.work_schedule_name,
            value: this.initData.work_schedule_code_bk
          })
        }
      },
      initApprove () {
        const requestJson = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
            screen_mode: this.approveRecord.screen_mode,
            target_company_code: this.approveRecord.target_company_code,
            target_group_code: this.approveRecord.target_group_code,
            target_employee_code: this.approveRecord.target_employee_code,
            approverl_company_code: this.approveRecord.approverl_company_code,
            approverl_group_code: this.approveRecord.approverl_group_code,
            approverl_employee_code: this.approveRecord.approverl_role_code ? '' : this.approveRecord.approverl_employee_code,
            approverl_role_code: this.approveRecord.approverl_role_code,
            application_number: this.approveRecord.application_number,
            route_type: this.approveRecord.route_type,
            route_number: this.approveRecord.route_number
          }
        }
        this.send(this.initApproveUrl, requestJson)
        .then(res => {
          this.afterInit1(res)
          this.afterInit2(res)
          this.afterInit3()
        })
      },
      initApply (targetDate = null, workScheduleCode = null) {
        this.isChanging = true
        let isOverwrite = false
        if (!targetDate || this.isApplicationExist) {
          isOverwrite = true
        }
        const requestJson = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
            company_code: this.$store.state.loginUser.companyCode,
            group_code: this.$store.state.loginUser.groupCode,
            employee_code: this.$store.state.loginUser.employeeCode,
            target_date: targetDate
          }
        }
        if (this.applyRecord) {
          requestJson.api_area.company_code = this.applyRecord.company_code
          requestJson.api_area.group_code = this.applyRecord.group_code
          requestJson.api_area.employee_code = this.applyRecord.employee_code
          requestJson.api_area.application_number = this.applyRecord.application_number
          if (this.applyRecord.is_paid_holiday) {
            requestJson.api_area.is_disp_corpus_message = false
          }
          if (workScheduleCode) {
            requestJson.api_area.work_schedule_code = workScheduleCode
          }
        } else {
          if (this.$route.params.attendanceRecord) {
            this.initData = this.$route.params.attendanceRecord
          } else {
            this.initData = this.attendanceRecord
          }
          if (this.initData) {
            switch (this.selectedApplicationFormCode) {
              case '0000000001':
                if (this.initData.application_group) {
                  if (this.initData.stamp_type === 1 && this.initData.application_group.imprint_correction_application_start_time_number !== null) {
                    requestJson.api_area.application_number = this.initData.application_group.imprint_correction_application_start_time_number
                  } else if (this.initData.stamp_type === 2 && this.initData.application_group.imprint_correction_application_end_time_number !== null) {
                    requestJson.api_area.application_number = this.initData.application_group.imprint_correction_application_end_time_number
                  } else if (this.initData.stamp_type === 3 && this.initData.application_group.imprint_correction_application_telework_number !== null) {
                    requestJson.api_area.application_number = this.initData.application_group.imprint_correction_application_telework_number
                  }
                }
                requestJson.api_area.work_schedule_code = this.initData.work_schedule_code_bk
                break
              case '0000000002':
                if (this.initData.application_group && this.initData.application_group.overwork_application_number !== null) {
                  requestJson.api_area.application_number = this.initData.application_group.overwork_application_number
                }
                break
              case '0000000004':
                if (this.initData.application_group && this.initData.application_group.late_night_overwork_application_number !== null) {
                  requestJson.api_area.application_number = this.initData.application_group.late_night_overwork_application_number
                }
                break
              case '0000000005':
                if (this.initData.application_group && this.initData.application_group.late_application_number !== null) {
                  requestJson.api_area.application_number = this.initData.application_group.late_application_number
                }
                requestJson.api_area.work_schedule_code = this.initData.work_schedule_code_bk
                break
              case '0000000006':
                if (this.initData.application_group && this.initData.application_group.early_departure_application_number !== null) {
                  requestJson.api_area.application_number = this.initData.application_group.early_departure_application_number
                }
                requestJson.api_area.work_schedule_code = this.initData.work_schedule_code_bk
                break
              case '0000000007':
                if (this.initData.application_group && this.initData.application_group.job_holiday_application_number !== null) {
                  requestJson.api_area.application_number = this.initData.application_group.job_holiday_application_number
                }
                break
              case '0000000008':
                if (this.initData.application_group && this.initData.application_group.parental_leave_application_number !== null) {
                  requestJson.api_area.application_number = this.initData.application_group.parental_leave_application_number
                }
                break
              case '0000000009':
              case '0000000010':
                if (this.initData.application_group && this.initData.application_group.paid_holiday_application_number !== null) {
                  requestJson.api_area.application_number = this.initData.application_group.paid_holiday_application_number
                }
                break
              case '0000000011':
              case '0000000012':
                if (this.initData.application_group && this.initData.application_group.transfer_work_application_number !== null) {
                  requestJson.api_area.application_number = this.initData.application_group.transfer_work_application_number
                }
                if (this.initData.application_group && this.initData.application_group.transfer_holiday_application_number !== null) {
                  requestJson.api_area.application_number = this.initData.application_group.transfer_holiday_application_number
                }
                break
              case '0000000016':
                requestJson.api_area.job_month = this.initData.select_month
                break
            }
            requestJson.api_area.employee_code = this.initData.employee_code
            requestJson.api_area.target_date = this.initData.target_date
          } else {
            if (this.selectedApplicationFormCode === '0000000001') {
              if (!requestJson.api_area.target_date && !!this.$route.params.target_date) {
                requestJson.api_area.target_date = this.$route.params.target_date
              }
            }
            switch (this.selectedApplicationFormCode) {
              case '0000000001':
              case '0000000005':
              case '0000000006':
                requestJson.api_area.work_schedule_code = workScheduleCode
                break
            }
          }
        }
        if (this.applicationFormCode === '0000000002' ||
            this.applicationFormCode === '0000000004' ||
            this.applicationFormCode === '0000000007') {
          if (workScheduleCode) {
            requestJson.api_area.work_schedule_code = workScheduleCode
          }
        } else if (this.applicationFormCode === '0000000005' || this.applicationFormCode === '0000000006') {
          requestJson.api_area.application_form_code = this.selectedApplicationFormCode
        } else if (this.applicationFormCode === '0000000011' || this.applicationFormCode === '0000000012') {
          if (targetDate === this.formatToday() || requestJson.api_area.application_number) {
            requestJson.api_area.target_date = ''
          }
        } else if (this.applicationFormCode === '0000000013' ||
            this.applicationFormCode === '0000000016' ||
            this.applicationFormCode === '0000000018' ||
            this.applicationFormCode === '0000000019' ||
            this.applicationFormCode === '0000000020') {
          delete requestJson.api_area.target_date
        }
        if (targetDate) {
          if (!this.applicationInfoDto.target_group_code || !this.applicationInfoDto.target_employee_code) {
            swal({
              title: this.$t('message.applicant_not_found'),
              html: this.$t('message.applicant_not_found_description'),
              type: 'error',
              confirmButtonClass: 'btn btn-warning btn-sm btn-default',
              buttonsStyling: false,
              allowOutsideClick: false
            })
            return
          }
        }
        if (!!this.applicationInfoDto.target_group_code && !!this.applicationInfoDto.target_employee_code) {
          requestJson.api_area.group_code = this.applicationInfoDto.target_group_code
          requestJson.api_area.employee_code = this.applicationInfoDto.target_employee_code
        }
        this.send(this.initApplyUrl, requestJson, true, true)
        .then(res => {
          this.isChanging = false
          if (this.applicationInfoDto.re_application_number) {
            const reApplicationNumber = this.applicationInfoDto.re_application_number
            this.afterInit1(res)
            this.applicationInfoDto.re_application_number = reApplicationNumber
            this.afterInit3()
          } else if (isOverwrite || res.api_area.applicationInfoDto.application_number) {
            this.afterInit1(res)
            this.afterInit2(res)
            this.afterInit3()
          } else {
            const application = {...this.application}
            switch (this.applicationFormCode) {
              case '0000000001':
                const workScheduleList = []
                for (const row of res.api_area.work_schedule_list) {
                  for (const row2 of res.common_area.combo_data.work_schedule_list) {
                    if (row === row2.value) {
                      workScheduleList.push(row2)
                      break
                    }
                  }
                }
                application.work_schedule_list = workScheduleList
                application.breakTimeListErrors = []
                application.break_time_list = []
                for (const row of res.api_area.imprintCorrectionApplicationDetail.break_time_list) {
                  row.start_time = this.convertHhmmss2Hhmm(row.start_time)
                  row.end_time = this.convertHhmmss2Hhmm(row.end_time)
                  row.re_start_time = this.convertHhmmss2Hhmm(row.re_start_time)
                  row.re_end_time = this.convertHhmmss2Hhmm(row.re_end_time)
                  application.break_time_list.push(row)
                }
                if (!this.initData) {
                  if (res.api_area.imprintCorrectionApplication.work_schedule_code) {
                    application.work_schedule_code = res.api_area.imprintCorrectionApplication.work_schedule_code
                  } else if (res.api_area.work_schedule_list.length > 0) {
                    application.work_schedule_code = res.api_area.work_schedule_list[0]
                  } else {
                    application.work_schedule_code = ''
                  }
                  application.telework_flg = res.api_area.applicationInfoDto.application_number === 0 ? !!res.api_area.imprintCorrectionApplication.before_telework_flg : !!res.api_area.imprintCorrectionApplication.telework_flg
                  if (res.api_area.imprintCorrectionApplication.before_start_time) {
                    application.before_start_hours = String(parseInt(res.api_area.imprintCorrectionApplication.before_start_time.split(':')[0], 10))
                    application.before_start_minutes = res.api_area.imprintCorrectionApplication.before_start_time.split(':')[1]
                  } else {
                    application.before_start_hours = ''
                    application.before_start_minutes = ''
                  }
                  if (res.api_area.imprintCorrectionApplication.before_end_time) {
                    application.before_end_hours = String(parseInt(res.api_area.imprintCorrectionApplication.before_end_time.split(':')[0], 10))
                    application.before_end_minutes = res.api_area.imprintCorrectionApplication.before_end_time.split(':')[1]
                  } else {
                    application.before_end_hours = ''
                    application.before_end_minutes = ''
                  }
                  application.org_telework_flg = res.api_area.imprintCorrectionApplication.before_telework_flg
                  application.org_start_hours = res.api_area.imprintCorrectionApplication.before_start_hours
                  application.org_start_minutes = res.api_area.imprintCorrectionApplication.before_start_minutes
                  application.org_end_hours = res.api_area.imprintCorrectionApplication.before_end_hours
                  application.org_end_minutes = res.api_area.imprintCorrectionApplication.before_end_minutes
                  for (const row of application.apply_items.items) {
                    row.value = false
                  }
                }
                this.application = application
                break
              case '0000000002':
                this.comboData.reasons_over_work_contents_list = ['']
                if (res.api_area.overtimeApplication.reasons_over_work_contents_style1) {
                  this.comboData.reasons_over_work_contents_list.push(res.api_area.overtimeApplication.reasons_over_work_contents_style1)
                }
                if (res.api_area.overtimeApplication.reasons_over_work_contents_style2) {
                  this.comboData.reasons_over_work_contents_list.push(res.api_area.overtimeApplication.reasons_over_work_contents_style2)
                }
                if (!this.initData) {
                  if (res.api_area.overtimeApplication.overwork_minutes > 0) {
                    application.overtime_hours = String(Math.floor(res.api_area.overtimeApplication.overwork_minutes / 60))
                    application.overtime_minutes = ('0' + String(res.api_area.overtimeApplication.overwork_minutes % 60)).slice(-2)
                  }
                  if (res.api_area.overtimeApplication.work_schedule_code) {
                    application.work_schedule_code = res.api_area.overtimeApplication.work_schedule_code
                  } else if (res.common_area.combo_data.work_schedule_list.length > 0) {
                    application.work_schedule_code = res.common_area.combo_data.work_schedule_list[0].value
                  } else {
                    application.work_schedule_code = ''
                  }
                }
                application.limit_legal_one_month_minutes_style1 = res.api_area.overtimeApplication.limit_legal_one_month_minutes_style1
                application.limit_legal_one_month_minutes_style2 = res.api_area.overtimeApplication.limit_legal_one_month_minutes_style2
                application.reasons_over_work_contents_style1 = res.api_area.overtimeApplication.reasons_over_work_contents_style1
                application.reasons_over_work_contents_style2 = res.api_area.overtimeApplication.reasons_over_work_contents_style2
                application.limit_legal_one_month_minutes = res.api_area.overtimeApplication.limit_legal_one_month_minutes
                application.monthly_overwork_minutes = res.api_area.overtimeApplication.monthly_overwork_minutes
                application.remaining_monthly_overwork_minutes = application.limit_legal_one_month_minutes - application.monthly_overwork_minutes
                this.application = application
                break
              case '0000000003':
                this.isDeleteStampPopup = res.api_area.is_delete_stamp_popup
                this.deleteStampMessage = !res.api_area.delete_stamp_message ? '該当日は打刻されています。' : res.api_area.delete_stamp_message
                this.deleteStampMessageAction = !res.api_area.delete_stamp_message_action ? '打刻を削除し、事由を変更しても宜しいでしょうか？' : res.api_area.delete_stamp_message_action
                this.groundList = res.common_area.combo_data.ground_list
                if (!this.initData) {
                  // if (res.api_area.groundConfirmEmployee.work_schedule_code) {
                  //   application.work_schedule_code = res.api_area.groundConfirmEmployee.work_schedule_code
                  // } else if (res.common_area.combo_data.work_schedule_list.length > 0) {
                  //   application.work_schedule_code = res.common_area.combo_data.work_schedule_list[0].value
                  // } else {
                  //   application.work_schedule_code = ''
                  // }
                  if (res.api_area.groundConfirmEmployee.re_ground_data) {
                    application.actual_ground = res.api_area.groundConfirmEmployee.re_ground_data.label
                  } else {
                    application.actual_ground = ''
                  }
                  this.application = application
                }
                break
              case '0000000004':
                if (!this.initData) {
                  if (res.api_area.lateNightOverworkApplication.late_night_overwork_minutes) {
                    application.late_night_overwork_minutes = String(res.api_area.lateNightOverworkApplication.late_night_overwork_minutes)
                  }
                  if (res.api_area.lateNightOverworkApplication.work_schedule_code) {
                    application.work_schedule_code = res.api_area.lateNightOverworkApplication.work_schedule_code
                  } else if (res.common_area.combo_data.work_schedule_list.length > 0) {
                    application.work_schedule_code = res.common_area.combo_data.work_schedule_list[0].value
                  } else {
                    application.work_schedule_code = ''
                  }
                  this.application = application
                }
                break
              case '0000000005':
              case '0000000006':
                if (!this.initData) {
                  if (res.api_area.lateTimeApplication && res.api_area.lateTimeApplication.work_schedule_code) {
                    application.work_schedule_code = res.api_area.lateTimeApplication.work_schedule_code
                  } else if (res.api_area.earlyDepartureTimeApplication && res.api_area.earlyDepartureTimeApplication.work_schedule_code) {
                    application.work_schedule_code = res.api_area.earlyDepartureTimeApplication.work_schedule_code
                  } else if (res.common_area.combo_data.work_schedule_list.length > 0) {
                    application.work_schedule_code = res.common_area.combo_data.work_schedule_list[0].value
                  } else {
                    application.work_schedule_code = ''
                  }
                  if (res.api_area.lateTimeApplication && res.api_area.lateTimeApplication.job_start) {
                    application.job_start = res.api_area.lateTimeApplication.job_start
                    application.job_end = res.api_area.lateTimeApplication.job_end
                  } else {
                    application.job_start = res.api_area.earlyDepartureTimeApplication.job_start
                    application.job_end = res.api_area.earlyDepartureTimeApplication.job_end
                  }
                  this.application = application
                }
                break
              case '0000000007':
                if (!this.initData) {
                  if (res.api_area.holidayWorkApplication.holiday_work_hours) {
                    application.holiday_minutes = String(res.api_area.holidayWorkApplication.holiday_work_hours)
                  }
                  if (res.api_area.holidayWorkApplication.work_schedule_code) {
                    application.work_schedule_code = res.api_area.holidayWorkApplication.work_schedule_code
                  } else if (res.common_area.combo_data.work_schedule_list.length > 0) {
                    application.work_schedule_code = res.common_area.combo_data.work_schedule_list[0].value
                  } else {
                    application.work_schedule_code = ''
                  }
                  this.application = application
                }
                break
              case '0000000009':
              case '0000000010':
                if (application.paid_holiday_type.value === 1) {
                  this.calcDays()
                }
                break
              case '0000000011':
              case '0000000012':
                // if (res.api_area.transferHolidayWorkApplication.work_schedule_code) {
                //   application.work_schedule_code = res.api_area.transferHolidayWorkApplication.work_schedule_code
                // } else if (res.common_area.combo_data.work_schedule_list.length > 0) {
                //   application.work_schedule_code = res.common_area.combo_data.work_schedule_list[0].value
                // } else {
                //   application.work_schedule_code = ''
                // }
                this.application = application
                break
            }
          }
          this.isApplicationExist = res.api_area.applicationInfoDto.application_number !== 0
        }).catch(() => {
          this.isChanging = false
        })
      }
    },
    created () {
      this.selectedApplicationFormCode = this.applicationFormCode
      if (this.approveRecord) {
        this.initApprove()
      } else {
        this.initApply()
      }
    }
  }
</script>
<style>
</style>
