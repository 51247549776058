<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: #f5f5f5; z-index: 10;" v-if="!initialized"></div>
    <modal name="detail" :draggable="draggable" :clickToClose="false" height="auto" :width="modalWidth" :pivotX="0.6">
      <div class="modal-body">
        <Detail :mode="mode" :params="params" @close="hide"/>
      </div>
    </modal>
    <div class="row">
      <div class="col-sm-6">
      </div>
      <div class="col-sm-6">
        <Help class="pull-right" :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
      </div>
    </div>
    <div ref="frame">
    <form method="#" action="#">
      <div class="card">
        <div class="card-header search-condition-title" @click="toggleSearchCondition">
          <div style="display: inline-block; width: 49%;">
            <h4 class="card-title">
              {{$t('label.search_condition')}}
            </h4>
          </div>
          <div style="display: inline-block; width: 50%; text-align: right; vertical-align: middle;">
            <template v-if="showSearchCondition">
              <span class="ti-angle-up pull-right"></span>
            </template>
            <template v-else>
              <span class="ti-angle-down pull-right"></span>
            </template>
          </div>
        </div>
        <div class="card-content" v-if="showSearchCondition">
          <input type="text" name="dummy" style="display: none;">
          <div class="form-group search-condition-item" style="display: inline-block">
            <label>{{labels.group_code}}</label>
            <input type="text" :placeholder="$t('placeholder.group_code')" maxlength="10" class="form-control length10" v-model="searchCond.group_code">
          </div>
          <div class="form-group search-condition-item" style="display: inline-block">
            <label>{{labels.group_name}}</label>
            <input type="text" :placeholder="$t('placeholder.group_name')" maxlength="50" class="form-control length100" v-model="searchCond.group_name">
          </div>
          <div class="search-button-area">
            <template v-if="labels.entry === 1">
              <button type="button" class="btn btn-primary btn-sm btn-default" @click="showAdd">
                <span class="btn-label"><i class="ti-settings"></i></span>
                {{$t('button.new')}}
              </button>
            </template>
            <template v-if="labels.search === 1">
              <button type="button" class="btn btn-search btn-sm btn-default" @click="search">
                <span class="btn-label"><i class="ti-search"></i></span>
                {{$t('button.search')}}
              </button>
            </template>
          </div>
        </div>
      </div>
    </form>
    <MessageArea :page-type="pageType.search" :message="message" :placeholders="[pageTitle]"/>
    <template v-if="tableData && tableData.length > 0">
      <div class="card">
        <div class="card-header search-result-title">
          <h4 class="card-title">
            {{$t('label.search_result')}}
          </h4>
        </div>
        <div class="card-content">
          <div class="row" ref="summary">
            <div class="col-sm-6 pagination-info">
              <el-select
                class="select-success length2"
                :disabled="isChanged"
                v-model="pagination.perPage">
                <el-option
                  class="select-success"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </div>
            <div class="col-sm-6">
            </div>
          </div>
          <div style="display: inline-block; vertical-align: top; margin-top: 10px;"><div :style="tableWrapperStyle">
            <el-table class="table-width"
                      :data="pagedData"
                      :empty-text="$t('label.no_data')"
                      border
                      :header-cell-style="() => 'border-right: 1px solid #ddd;'"
                      :style="tableStyle"
                      ref="table"
                      :row-class-name="tableRowClassName">
              <el-table-column :width="130"
                               v-if="showOperation"
                               :key="componentKey"
                               :label="$t('label.operation')">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <template v-if="labels.preview === 1">
                    <a class="btn btn-simple btn-icon like" @click="handleView(props.$index, props.row)"><i class="ti-file" :title="$t('button.view')"></i></a>
                  </template>
                  <template v-if="labels.update === 1 && canUpdate(props.row)">
                    <a class="btn btn-simple btn-icon edit" @click="handleEdit(props.$index, props.row)"><i class="ti-pencil-alt" :title="$t('button.update')"></i></a>
                  </template>
                  <template v-if="labels.delete === 1 && canDelete(props.row)">
                    <a class="btn btn-simple btn-icon btn-danger remove" @click="handleDelete(props.$index, props.row)"><i class="ti-close" :title="$t('button.delete')"></i></a>
                  </template>
                </template>
              </el-table-column>
              <el-table-column v-if="showGroup" :width="getColumnWidthStrong(tableData, labels.group_ryaku === undefined ? labels.group_ryaku === undefined ? '' : labels.group_ryaku : labels.group_ryaku, 'group_code', 'group_name')" :label="labels.group_ryaku === undefined ? labels.group_ryaku === undefined ? '' : labels.group_ryaku : labels.group_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div class="strong-text">
                    {{props.row.group_name}}
                  </div>
                  <div style="text-align: left;">
                    <small>{{props.row.group_code}}</small>
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showDivisionReservationDate" :width="getColumnWidth(tableData, labels.division_reservation_date_ryaku === undefined ? labels.divisionReservationDate_ryaku === undefined ? '' : labels.divisionReservationDate_ryaku : labels.division_reservation_date_ryaku, 'division_reservation_date')" :label="labels.division_reservation_date_ryaku === undefined ? labels.divisionReservationDate_ryaku === undefined ? '' : labels.divisionReservationDate_ryaku : labels.division_reservation_date_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.division_reservation_date}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showTermFrom" :width="getColumnWidthStrong(tableData, labels.term_from_ryaku === undefined ? labels.termFrom_ryaku === undefined ? '' : labels.termFrom_ryaku : labels.term_from_ryaku, 'term_from')" :label="labels.term_from_ryaku === undefined ? labels.termFrom_ryaku === undefined ? '' : labels.termFrom_ryaku : labels.term_from_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div class="strong-text">
                    {{props.row.term_from}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showTermTo" :width="getColumnWidthStrong(tableData, labels.term_to_ryaku === undefined ? labels.termTo_ryaku === undefined ? '' : labels.termTo_ryaku : labels.term_to_ryaku, 'term_to')" :label="labels.term_to_ryaku === undefined ? labels.termTo_ryaku === undefined ? '' : labels.termTo_ryaku : labels.term_to_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div class="strong-text">
                    {{props.row.term_to}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showUpperGroup" :width="getColumnWidthStrong(tableData, labels.upper_group_ryaku === undefined ? labels.upperGroup_ryaku === undefined ? '' : labels.upperGroup_ryaku : labels.upper_group_ryaku, 'upper_group_code', 'upper_group_name')" :label="labels.upper_group_ryaku === undefined ? labels.upperGroup_ryaku === undefined ? '' : labels.upperGroup_ryaku : labels.upper_group_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div class="strong-text">
                    {{props.row.upper_group_name}}
                  </div>
                  <div style="text-align: left;">
                    <small>{{props.row.upper_group_code}}</small>
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showDivisionGroup" width="270" :label="labels.division_group_ryaku === undefined ? labels.divisionGroup_ryaku === undefined ? '' : labels.divisionGroup_ryaku : labels.division_group_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    <div v-for="(row, index) in props.row.division_group_list" :key="index" style="white-space: normal">
                      {{row.label}}
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            </div>
            <div class="row" ref="pager">
              <div class="col-sm-4 pagination-info">
                <p class="category">{{$t('label.display_range').replace('{total}', total).replace('{from}', from + 1).replace('{to}', to)}}</p>
              </div>
              <div class="col-sm-8" style="padding: 0">
                <p-pagination class="pull-right"
                              v-model="pagination.currentPage"
                              :per-page="pagination.perPage"
                              :total="pagination.total">
                </p-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    </div>
  </div>
</template>
<script>
  import GroupDivisionSearchMixin from '@/components/Dashboard/Views/Generated/GroupDivisionSearchMixin'

  export default {
    name: 'GroupDivisionSearch',
    methods: {
      afterSearch (res) {
        this.labels.upper_group = this.$t('label.upper_group').replace('{item}', this.labels.group)
        this.labels.division_group = this.$t('label.division_group').replace('{item}', this.labels.group)
        for (const row of this.tableData) {
          row.group_division_code = row.group_code
        }
      }
    },
    mixins: [GroupDivisionSearchMixin]
  }
</script>
<style scoped>
</style>
