<template>
  <div class="card">
    <div class="card-content">
      <div class="form-group">
        <div style="display: inline-block; margin: 0; padding: 0;">
          <ValidationTextArea 
            classes="form-control length90"
            :rows="3" 
            v-model="approveInfoDto.comment" 
            :disabled="isReadOnly" 
            :item-label="$t('label.comment')"
            :rules="isStagnation ? 'required' : ''"
          ></ValidationTextarea>
        </div>
      </div>
      <div class="form-group">
        <div class="width90" style="display: inline-block; vertical-align: top;">
          <label>{{$t('label.attached_file')}}</label>
          <button type="button" class="btn btn-sm btn-default btn-primary" :disabled="isReadOnly" @click="addFile">{{$t('button.select')}}</button>
          <input
            style="display: none"
            ref="input"
            type="file"
            @change="selectedFile()"
          >                    
        </div>
        <div style="margin: 0; padding: 0;">
          <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" border
                    :data="approveInfoDto.attached_files"
                    :empty-text="$t('label.no_data')"
                    style="margin-bottom: 10px;">
            <el-table-column width="60" :label="$t('label.delete')" align="center">
              <template slot-scope="props">
                <template v-if="!props.row.isFixed">
                  <div style="text-align: left;" v-show="!props.row.is_fixed">
                    <input type="checkbox" v-model="props.row.selected" class="gray" :disabled="isReadOnly">
                  </div>
                </template>
              </template>
            </el-table-column>
            <el-table-column min-width="500" :label="labels.append_title" align="center">
              <template slot-scope="props">
                <div style="text-align: left;">
                  <template v-if="props.row.is_fixed">
                    <a
                      class="btn btn-simple btn-info"
                      style="padding: 0; color: blue; text-decoration: underline;"
                      @click="download(props.row)"
                    >{{props.row.append_title}}</a>
                  </template>
                  <template v-else>
                    <ValidationText
                      v-model="approveInfoDto.append_title"
                      item-name="append_title"
                      max-length="20"
                      classes="form-control length60"
                      rules="required"
                      :item-label="labels.append_title"
                      :placeholder="$t('placeholder.append_title')"
                      :disabled="isReadOnly"
                      :is-error-position-top="true"
                    />
                  </template>
                </div>
              </template>
            </el-table-column>
            <el-table-column min-width="100" :label="labels.append_date" align="center">
              <template slot-scope="props">
                {{props.row.append_date}}
              </template>
            </el-table-column>
            <el-table-column min-width="100" :label="$t('label.attached_employee')" align="center">
              <template slot-scope="props">
                <div style="text-align: left;">
                  <small>{{props.row.employee_code}}</small>
                </div>
                <div style="text-align: left;">
                  {{props.row.employee_name}}
                </div>
              </template>
            </el-table-column>
          </el-table>
          <button type="button" class="btn btn-sm btn-default btn-primary" :disabled="isReadOnly" @click="deleteFile">{{$t('button.delete')}}</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import ValidationText from '@/components/UIComponents/Inputs/ValidationTextHorizontal'
  import ValidationTextArea from '@/components/UIComponents/Inputs/ValidationTextArea'
  import mixin from '@/lib/mixin'
  import swal from 'sweetalert2'

  export default{
    mixins: [mixin],
    components: {
      ValidationText,
      ValidationTextArea
    },
    props: {
      value: Object,
      isReadOnly: Boolean,
      labels: Object,
      comboData: Object
    },
    data () {
      return {
        id: 0,
        selectedDay: null,
        isBlank: true,
        tableData: [
          { start_time: '10:00', end_time: '11:00', time_breaked: '1:00' },
          { start_time: '15:30', end_time: '16:00', time_breaked: '0:30' },
          { start_time: '', end_time: '', time_breaked: '' }
        ],
        approveInfoDto: {},
        currentIndex: 0,
        application: {
          re_application_number: undefined,
          application_type: '残業申請',
          apply_date: '',
          a_company_code: this.$store.state.loginUser.companyCode,
          a_company_name: this.$store.state.loginUser.companyName,
          a_group_code: this.$store.state.loginUser.groupCode,
          a_group_name: this.$store.state.loginUser.groupName,
          a_employee_code: this.$store.state.loginUser.employeeCode,
          a_employee_name: this.$store.state.loginUser.employeeName,
          b_company_code: this.$store.state.loginUser.companyCode,
          b_company_name: this.$store.state.loginUser.companyName,
          b_group_code: this.$store.state.loginUser.groupCode,
          b_group_name: this.$store.state.loginUser.groupName,
          b_employee_code: this.$store.state.loginUser.employeeCode,
          b_employee_name: this.$store.state.loginUser.employeeName,
          c_company_code: undefined,
          c_company_name: undefined,
          c_group_code: undefined,
          c_group_name: undefined,
          c_employee_code: undefined,
          c_employee_name: undefined,
          target_date: undefined,
          overtime_hours: undefined,
          overtime_minutes: undefined,
          overtime_reason: undefined,
          overtime_content: undefined,
          screen_mode: '1'
        },
        errMsgs1: [],
        errMsgs2: [],
        res: {},
        selectedComment: undefined,
        activityNames: {},
        size: 3 * 1024 * 1024
      }
    },
    computed: {
      isStagnation () {
        return window.currentUrl === '/09/menu09Screen012/search'
      }
    },
    methods: {
      download (row) {
        let self = this
        const httpsReference = this.$firebase.storage()
          .ref()
          .child(row.append_path)
        httpsReference.getDownloadURL().then(function (url) {
          self.$axios.get(url, {
            'responseType': 'blob'
          }).then(res => {
            const fileName = row.append_path.substring(row.append_path.lastIndexOf('_') + 1)
            const extension = row.append_path.substring(row.append_path.lastIndexOf('.') + 1)
            let blob = new Blob([res.data], { type: self.getContentType(extension) })
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = fileName
            link.click()
          })
        }).catch(function (error) {
          console.log(error)
        })
      },
      async selectedFile () {
        const file = this.$refs.input.files[0]
        if (!file) {
          return
        }
        if (file.size > this.size) {
          swal({
            text: this.$t('message.file_size_over').replace('{size}', this.formatNumber(this.size / 1024)),
            type: 'error',
            confirmButtonClass: 'btn btn-warning btn-sm btn-default',
            buttonsStyling: false,
            allowOutsideClick: false
          })
          return
        }
        let self = this
        // 生成する文字列の長さ
        var l = 16
        // 生成する文字列に含める文字セット
        var c = 'abcdefghijklmnopqrstuvwxyz0123456789'
        var cl = c.length
        var r = ''
        for (var i = 0; i < l; i++) {
          r += c[Math.floor(Math.random() * cl)]
        }
        const appendPath = `temp/${r}_${file.name}`
        const httpsReference = this.$firebase.storage()
          .ref()
          .child(appendPath)
        httpsReference.put(file).then(function (snapShot) {
          self.approveInfoDto.append_path = appendPath
          self.approveInfoDto.append_title = file.name
          let attachedFile = {
            is_fixed: false,
            selected: false,
            file_name: file.name,
            append_title: file.name,
            append_date: self.formatNow(),
            employee_code: self.$store.state.loginUser.employeeCode,
            employee_name: self.$store.state.loginUser.employeeName
          }
          if (self.approveInfoDto.attached_files.length > 0 && !self.approveInfoDto.attached_files[0].is_fixed) {
            self.approveInfoDto.attached_files.splice(0, 1)
          }
          self.approveInfoDto.attached_files.unshift(attachedFile)
        }).catch(function (error) {
          console.log(error)
        })
      },
      addFile () {
        this.$refs.input.click()
      },
      deleteFile () {
        for (let i = this.approveInfoDto.attached_files.length - 1; i >= 0; i--) {
          if (this.approveInfoDto.attached_files[i].selected) {
            this.approveInfoDto.attached_files.splice(i, 1)
            this.approveInfoDto.append_path = ''
          }
        }
      }
    },
    watch: {
      value () {
        this.approveInfoDto = this.value
      }
    },
    mounted () {
      this.approveInfoDto = this.value
    }
  }
</script>
<style scoped>
</style>
